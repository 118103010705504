import { Listbox, Transition } from "@headlessui/react";
import React, { ReactNode, useId } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import {
 ChevronDownIcon,
 ChevronUpDownIcon,
 ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { LabelValuePair } from "../../data/type";

type Option = LabelValuePair & React.ComponentProps<typeof Listbox.Option>;

export const withDefaultOp = (options: Option[], label: string = "초기화") => {
 const newOptions = [
  {
   value: undefined,
   label,
  },
  ...options,
 ];
 return newOptions;
};

interface IProp
 extends Omit<
  React.HTMLProps<HTMLDivElement>,
  "label" | "onChange" | "placeholder" | "value" | "size"
 > {
 placeholder?: ReactNode;
 value?: any;
 onChange: (val: any) => void;
 options?: Option[];
 selectFormat?: (value: any) => ReactNode;
 label?: React.ReactNode;
 size?: "L";
 popSize?: "L";
 nativeMode?: boolean;
 selectClasses?: string;
 coverIconClasses?: string;
 noCicon?: boolean;
}

export const NativeSelecter: React.FC<IProp> = ({
 value,
 label,
 placeholder = "선택",
 onChange: handleChange,
 options,
 selectFormat = (val: any) => val,
 size,
 className,
 popSize,
 disabled,
 readOnly,
 selectClasses,
 coverIconClasses,
 nativeMode = true,
 noCicon,
 ...props
}) => {
 const id = useId();

 return (
  <div
   className={twMerge(
    classNames(
     "mx-auto relative border-[0.56vw] md:border-[4.27px] text-neutral-200 rounded-[0.56vw] md:rounded-[4.27px] text-[3.89vw] md:text-[29.87px]",
     className
    )
   )}
  >
   <select
    value={value}
    onChange={(e) => {
     handleChange(e.currentTarget.value);
    }}
    className={twMerge(
     classNames(
      " py-[3.33vw] md:py-[25.6px] px-[5.56vw] text-gray-800 md:px-[42.67px] no-default-select-arrow w-full",
      selectClasses
     )
    )}
   >
    {value}
    {options?.map((op) => (
     <option selected={op.value === value} value={op.value} key={op.value}>
      {op.label}
     </option>
    ))}
   </select>
   {!noCicon && (
    <ChevronUpDownIcon
     className={twMerge(
      classNames(
       "absolute right-2 bg-white top-1/2 text-gray-800 -translate-y-1/2 w-[6.67vw] md:w-[51.2px] h-[6.67vw] md:h-[51.2px]",
       coverIconClasses
      )
     )}
    />
   )}
  </div>
 );
};
