import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import classNames from "classnames";
import { TUseModal } from "../hook/useModal";
import { twMerge } from "tailwind-merge";

interface IProp {
 transition?: any;
 modalHook: TUseModal;
 bodyclasses?: string;
 children?: React.ReactNode;
 outsideClickClose?: boolean;
}

export const Modal: React.FC<IProp> = ({
 modalHook,
 transition = {
  enter: "transition-opacity duration-450",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition-opacity duration-450",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
 },
 outsideClickClose = true,
 bodyclasses,
 children,
}) => {
 return (
  <Transition {...transition} appear show={modalHook.isOpenStart} as={Fragment}>
   <Dialog
    className="relative "
    style={{
     zIndex: 100,
    }}
    onClose={() => {}}
   >
    <Dialog.Panel>
     <div
      onMouseDown={(e) => {
       e.preventDefault();
       e.stopPropagation();
       if (outsideClickClose) modalHook.close();
      }}
      className="bg-black/80 fixed  inset-0 flex items-center justify-center  "
     >
      <div
       onMouseDown={(e) => {
        e.stopPropagation();
       }}
       onClick={(e) => {
        e.stopPropagation();
       }}
       className={twMerge(
        classNames("bg-white w-[600px]   p-8 px-6  rounded-[20px]", bodyclasses)
       )}
      >
       {children}
      </div>
     </div>
    </Dialog.Panel>
   </Dialog>
  </Transition>
 );
};

export default Modal;
