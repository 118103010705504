import { useEffect, useState } from "react";
import {
 LocalStorage,
 SessionFisrtLocalStorage,
} from "../utils/localstorage/LocalStorage";

export const useMemorialState = (defaultValue: string, memoryKey: string) => {
 const memory = LocalStorage.getItem(memoryKey);
 const [val, setVal] = useState(memory || defaultValue);

 useEffect(() => {
  LocalStorage.setItem(memoryKey, val as string);
 }, [val]);

 return { val, setVal };
};

export const useMemorialInput = (defaultValue: string, memoryKey: string) => {
 const memory = LocalStorage.getItem(memoryKey);
 console.log("@@memory", memory);
 const [val, setVal] = useState(memory || defaultValue);

 console.log("@@val", val);

 useEffect(() => {
  if (typeof val === "string") LocalStorage.setItem(memoryKey, val as string);
 }, [val]);

 const value = val;
 const onChange = setVal as any;
 return { value, onChange };
};

export const useMemorialTagInput = (
 defaultTags: string[] = [],
 memoryKey: string
) => {
 const memory = LocalStorage.getItem(memoryKey);

 const [tags, setTags] = useState<string[]>(
  memory?.split(",") || [...defaultTags]
 );
 useEffect(() => {
  LocalStorage.setItem(memoryKey, tags.join(","));
 }, [tags]);
 return { tags, setTags };
};

export const useMemoryBooleanState = (
 memoryKey: string,
 defaultValue?: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
 const memory = SessionFisrtLocalStorage.getItem(memoryKey);
 const targetDefault = memory ?? defaultValue;
 const memoryVal = !!targetDefault;
 const [val, setVal] = useState<boolean>(memoryVal);

 useEffect(() => {
  if (val) LocalStorage.setItem(memoryKey, "T");
  else LocalStorage.removeItem(memoryKey);
 }, [val]);

 return [val, setVal];
};
