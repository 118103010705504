import { Outlet, useLocation } from "react-router-dom";
import { Suspense, useContext, useLayoutEffect, useMemo } from "react";
import { colorInlineObjectGenerater } from "../utils/cssvar";
import {
 ChatBubbleBottomCenterTextIcon,
 HomeIcon,
} from "@heroicons/react/20/solid";
import { AppContext } from "../hook/useApp.context";
import { L } from "../data/lang.static";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { RoomNumberPopModal } from "../components/RoomNumberPop.modal";
import { DevJsonViewer } from "../components/devTools/DevJson";
{
 /* <div className="flex gap-8">
<nav className="p-8 bg-slate-50">
 <ul className="py-8 flex flex-col  gap-4">
  <li className="p-4 rounded cursor-pointer">
   <Link to={"/item"}>장소</Link>
  </li>
  <li className="p-4 rounded">
   <Link to={"/user"}>유저</Link>
  </li>
  <li className="p-4 rounded">
   <Link to={"/visit"}>유저</Link>
  </li>
 </ul>
</nav>
<section>
 <Outlet />
</section>
</div> */
}

export default function CustomerLayout() {
 const { lang, roomNumberPOpModal, store } = useContext(AppContext);
 const navigate = useNavigate();

 const { pathname } = useLocation();
 const isChat = pathname?.includes("/chat");
 const isIntro = pathname === "/";
 const isHome = !isChat;

 type Nav = {
  path: string;
  Icon: typeof HomeIcon;
  title: string;
  selected?: boolean;
 };

 const NavSet: Nav[] = [
  {
   Icon: HomeIcon,
   path: "/main",
   title: L["Home"],
   selected: isHome,
  },
  {
   Icon: ChatBubbleBottomCenterTextIcon,
   path: "/chat",
   title: L["Chat"],
   selected: isChat,
  },
 ];

 if (!store) return null;

 return (
  <div id="customerLayout" className="  max-w-screen-md mx-auto">
   <Suspense>
    <Outlet key={store._id}></Outlet>
   </Suspense>
   {!isIntro && (
    <div className="w-full fixed max-w-screen-md mx-auto left-1/2 -translate-x-1/2  bottom-0 h-[17.78vw] md:h-[136.53px] py-[2.78vw] md:py-[21.33px]  bg-white border-t border-neutral-200 justify-center items-center gap-[7.78vw] md:gap-[59.73px] inline-flex">
     {NavSet?.map((nav) => {
      const Icon = nav.Icon;

      return (
       <div
        onClick={() => {
         navigate(nav.path);
        }}
        key={nav.path + "nav"}
        className={classNames(
         "cursor-pointer text-neutral-400 w-[15.56vw] md:w-[119.47px] flex-col justify-center items-center gap-[2.78vw] md:gap-[21.33px] inline-flex",
         {
          "text-primary-500": nav.selected,
         }
        )}
       >
        <Icon className=" w-[6.67vw] md:w-[51.2px] h-[6.67vw] md:h-[51.2px] relative" />
        <div
         className={classNames(
          "whitespace-nowrap text-center text-[3.33vw] md:text-[25.6px] font-semibold font-['Pretendard'] leading-[3.33vw] md:leading-[25.6px]"
         )}
        >
         {nav.title}
        </div>
       </div>
      );
     })}
    </div>
   )}
   {roomNumberPOpModal?.isOpen && (
    <RoomNumberPopModal modalhook={roomNumberPOpModal} />
   )}
  </div>
 );
}
