import { encode, decode } from "blurhash";

const loadImage = async (src: string): Promise<HTMLImageElement> =>
 new Promise((resolve, reject) => {
  const img = new Image();
  img.setAttribute("crossOrigin", "");
  img.onload = () => resolve(img);
  img.onerror = (...args) => reject(args);
  img.src = src;
 });

const getImageData = (image: HTMLImageElement) => {
 const canvas = document.createElement("canvas");
 canvas.width = image.width;
 canvas.height = image.height;
 const context = canvas.getContext("2d") as any;
 context.drawImage(image, 0, 0);
 return context.getImageData(0, 0, image.width, image.height);
};

export const encodeImageToBlurhash = async (imageUrl: string) => {
 let image = await loadImage(imageUrl).catch((e) => {
  console.error("image load error", e);
  return null;
 });
 if (!image) return undefined;
 const imageData = getImageData(image);
 return encode(imageData.data, imageData.width, imageData.height, 4, 4);
};

export const blurHashToDataURL = (
 hash: string,
 width: number,
 height: number
) => {
 const pixels = decode(hash, width, height);
 const canvas = document.createElement("canvas");
 canvas.width = width;
 canvas.height = height;
 const ctx = canvas.getContext("2d");
 const imageData = ctx?.createImageData(width, height);
 if (imageData) {
  imageData.data.set(pixels);
  ctx?.putImageData(imageData, 0, 0);
 }
 return canvas.toDataURL();
};
