import { useEffect } from "react";

/**
 * Custom hook to manage event listeners.
 *
 * @param {EventTarget} target - The target to which the event listener will be added, e.g., window, document, HTMLElement.
 * @param {string} type - The event type, e.g., 'click', 'resize'.
 * @param {function} listener - The callback function to be executed when the event is triggered.
 */
function useWindowEventListener(type: any, listener: any) {
 useEffect(() => {
  if (!window) {
   // If target is not available, do nothing
   return;
  }

  // Add the event listener
  window.addEventListener(type, listener);

  // Return a cleanup function to remove the event listener
  return () => {
   window.removeEventListener(type, listener);
  };
 }, [type, listener]); // Whenever target, type, or listener changes, the effect will re-run.
}

export default useWindowEventListener;
