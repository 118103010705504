import { FileType } from "./type";

export function mapMimeTypeToCategory(mimeType: FileType) {
 if (mimeType.startsWith("image/")) {
  return "image";
 } else if (mimeType.startsWith("video/")) {
  return "video";
 } else if (mimeType.startsWith("audio/")) {
  return "audio";
 } else {
  return "file"; // default category
 }
}

export const callThumbNailSize = (url: string) => {
 // Regex pattern to match image URLs and capture the base URL and extension
 const splited = url.split(".");
 const extention = splited.pop();
 const baseUrl = splited.join(".");

 // Replacement format, where $1 is the base URL, and $3 is the extension
 // Applying the regex pattern
 return `${baseUrl}--thumb.jpeg`;
};

export const stopAllSpeaker = (except: HTMLAudioElement | null = null) => {
 const speakers = [...document.getElementsByClassName("Speaker")];
 speakers.forEach((sp) => {
  if (sp !== except) {
   (sp as HTMLAudioElement).pause();
  }
 });
};
