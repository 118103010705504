import { useState } from "react";
import { TUseModal } from "../../hook/useModal";
import Modal from "../Modal";
import { Input } from "../input";
import { Button } from "../button";

export interface IPromptModalInfo {
 titleElement?: any;
 title?: string;
 defaultValue?: string;
 messageLabel?: string;
 callBack: (message: string) => void;
 inputProps?: any;
 children?: any;
 [key: string]: any;
}

export interface IPomptModalProps {
 modalHook: TUseModal<IPromptModalInfo>;
}
export const PromptModal: React.FC<IPomptModalProps> = ({ modalHook }) => {
 const {
  callBack,
  titleElement,
  messageLabel,
  title,
  defaultValue = "",
  inputProps,
  children: infoChildren,
 } = modalHook.info || {};
 const [submitText, setSubmittext] = useState(defaultValue);

 const handleOk = () => {
  callBack!(submitText);
 };

 return (
  <Modal modalHook={modalHook}>
   <div>
    {infoChildren}
    <Input
     size="L"
     label={messageLabel}
     textarea
     {...inputProps}
     onChange={setSubmittext}
     value={submitText}
    />
   </div>
   <div className="flex">
    <Button
     variant="text"
     className="mr-2"
     onClick={modalHook.close}
     children="취소"
     size="L"
    />
    <Button size="L" onClick={handleOk} children="확인" />
   </div>
  </Modal>
 );
};

export default PromptModal;
