import { FcellFragment } from "../../app/apollo/type/graphql";
import { useContext } from "react";
import { AppContext } from "../../hook/useApp.context";
import { InfoCell } from "./InfoCell";
import { CellWrap } from "./CellWrap";

interface IInfoCellProp {
 cell: FcellFragment;
}

export const IconList: React.FC<IInfoCellProp> = ({ cell }) => {
 const { lang } = useContext(AppContext);
 return (
  <CellWrap cell={cell} className="col-span-2">
   {cell.name?.ko && (
    <div
     onClick={() => {
      const audio = document.getElementById(
       cell.key + "Speech"
      ) as HTMLAudioElement;
      if (audio) {
       if (!audio.paused) {
        audio.pause();
       } else {
        audio.play();
       }
      }
     }}
     className="text-[4.44vw]  md:text-[34.13px] font-bold !leading-none"
    >
     {lang(cell.name)}
    </div>
   )}
   {cell?.iconLines?.map((icl) => {
    return <InfoCell size="L" infocell={icl} />;
   })}
  </CellWrap>
 );
};
