import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { L, LangOps } from "../data/lang.static";
import { useLocation } from "react-router-dom";

import { Photo } from "../components/Photo";
import { BasicButton } from "../components/basic/Button.ratio";
import { useNavigate } from "react-router-dom";
import { NativeSelecter } from "../components/basic/NativeSelecter";
import { Group } from "../components/cell/Group";
import { DevJsonViewer } from "../components/devTools/DevJson";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export const Page = () => {
 const { pathname } = useLocation();
 const navigate = useNavigate();
 const { store, lang, langCode, updateComponent, resetLang } =
  useContext(AppContext);
 const page = store?.pages?.find((p) => p.path === pathname);

 return (
  <div className="w-full relative min-h-[100svh] lg:min-h-[100vh] pb-[22.78vw] md:pb-[176.53px] overflow-auto invisible-scrollbar bg-white shadow">
   <div className="w-[11.11vw] cursor-pointer md:w-[85.33px] h-[11.11vw] md:h-[85.33px] flex items-center justify-center bg-white rounded-full absolute z-20 left-[5.56vw] md:left-[42.67px] top-[5.56vw] md:top-[42.67px]">
    <ArrowLeftIcon
     onClick={() => {
      window.history.back();
     }}
     className="w-[6.67vw] md:w-[51.2px] h-[6.67vw] md:h-[51.2px]"
    />
   </div>
   <div className="w-full h-[83.33vw] md:h-[640px] relative rounded-bl-[13.89vw] md:rounded-bl-[106.67px] overflow-hidden rounded-br-[13.89vw] md:rounded-br-[106.67px]">
    <Photo
     className="object-cover bg-fixed object-center w-full h-full"
     size={1500}
     file={page?.image}
     defaultSrc={"/360x300.png"}
    />
   </div>
   <div className="px-[5.56vw] md:px-[42.67px] h-full  text-left  gap-[0.69vw] md:gap-[5.33px]  ">
    <h1 className=" font-semibold mb-[11.11vw] md:mb-[85.33px] mt-[5.56vw] md:mt-[42.67px]  text-[5.56vw] md:text-[42.67px]">
     {lang(page?.name)}
    </h1>
    {page?.groups?.map((g) => {
     return <Group group={g} key={g.key} />;
    })}
   </div>
  </div>
 );
};

export default Page;
