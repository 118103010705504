import { useCallback, useState } from "react";

export interface TUseInput<T = string> {
 value: T;
 onChangeValid: (value: boolean | string) => void;
 onChange: (foo: any) => any;
 isValid: any;
}

interface IProp {
 defulatValid?: boolean;
 prefix?: string;
 suffix?: string;
}

export function useInput<T = string>(
 defaultValue: T,
 config?: IProp
): T extends string ? TUseInput<string> : TUseInput<number> {
 const [value, setValue] = useState(defaultValue);
 const [isValid, setIsValid] = useState(config?.defulatValid);

 const onChange = useCallback((value: any) => {
  setValue(value);
 }, []);

 const onChangeValid = useCallback((value: boolean) => {
  setIsValid(value);
 }, []);

 // @ts-ignore
 return {
  value,
  onChange,
  isValid,
  onChangeValid,
 };
}
