import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import fetch from "cross-fetch";
import { getMainDefinition, getOperationName } from "@apollo/client/utilities";
import {
 ApolloClient,
 ApolloLink,
 NextLink,
 Operation,
 split,
} from "@apollo/client";
import { omits } from "../utils/omits";
import { removeUndefindOfObject } from "../utils/removeOfObject";
import { FILE_UPLOADS } from "./gql/file";
import { SERVER_URI } from "../uri";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { IS_DEV } from "../data/env.short";
import { cache } from "./cache";
import { LSK } from "../utils/localstorage/LocalStorage";
import { LocalStorage } from "../utils/localstorage/StorageAddition";
import { s6 } from "../utils/s4";
import { getFromUrl } from "../utils/getFromUrl";
const doNotRetryOperation = [getOperationName(FILE_UPLOADS)];

export const wsLink = new GraphQLWsLink(
 createClient({
  keepAlive: 3000,
  retryAttempts: 30,
  on: {
   connected() {
    console.info("ws connected");
   },
   closed() {
    console.info("ws closed");
   },
  },
  shouldRetry() {
   return true;
  },
  url: !IS_DEV
   ? "wss://api.hoteltalk.nfc-band.com/subscriptions"
   : "ws://localhost:4000/subscriptions",
 })
);

export const retryLink = new RetryLink({
 delay: {
  initial: 2000,
  max: 5000,
 },
 attempts: {
  max: 3,
  retryIf: (error, _operation) => {
   const clientFault = error?.statusCode === 400;
   return (
    !clientFault && !doNotRetryOperation.includes(_operation.operationName)
   );
  },
 },
});

export const errorLink = onError(
 ({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
   graphQLErrors.forEach((graphqlError) => {
    const { message, locations, path } = graphqlError;
    console.warn(
     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    );
    // if (process.env.NODE_ENV === "development") {
    //     toast.warn(message);
    // }
   });

   // logSend({
   //     level: "error",
   //     input: operation.variables,
   //     message: graphQLErrors,
   //     about: `graphql error encounter [${operation.operationName}]`,
   // });
  } else if (networkError) {
   if (operation.operationName.includes("Subscribe")) return forward(operation);
   console.log("networkError", networkError);
   // logSend({
   //     about: "network error encounter",
   //     level: "error",
   //     input: networkError,
   // });
   // const serverNotRespondCnt =
   //     SessionStroage.getNum("serverNotRespondCnt") || 0;
   // if (window.navigator.onLine) {
   //     SessionStroage.set(
   //         "serverNotRespondCnt",
   //         serverNotRespondCnt + 1
   //     );

   //     if (!noServerErrorMessage && serverNotRespondCnt > 5)
   //         toast.warn("서버가 응답하지 않습니다.", {
   //             toastId: "ServerIsNotRespond",
   //         });
   // } else {
   //     toast.warn("네트워크 연결 상태를 확인 해주세요.", {
   //         toastId: "ServerIsNotRespond",
   //     });
   // }
  } else {
   // SessionStroage.removeItem("serverNotRespondCnt");
  }
 }
);

export const cleanMutationField = (operation: Operation, forward: NextLink) => {
 const definition = operation?.query?.definitions.filter(
  (def) => def.kind === "OperationDefinition"
 )?.[0];
 const mutation = "mutation";
 if (
  definition?.kind == "OperationDefinition" &&
  definition?.operation === mutation
 ) {
  operation.variables = omits(operation.variables);
  return forward(operation);
 }
 return forward(operation);
};

export const getMachineId = () => {
 let machineId = LocalStorage.getItem(LSK.machineId) || "";
 if (!machineId) {
  machineId = s6();
  LocalStorage.setItem(LSK.machineId, machineId);
 }
 return machineId;
};

export const authLink = setContext((_, meta) => {
 if (!meta) return {};

 const sid = getFromUrl("id") || LocalStorage.getItem("sid");
 const room = LocalStorage.getItem("room");
 const lang = LocalStorage.getItem("lang");

 return {
  headers: {
   sid,
   language: lang,
   rid: room,
   machainId: getMachineId(),
   ...meta.headers,
   "apollo-require-preflight": "true",
  },
 };
});

export const fileUploadLink = createUploadLink({
 uri: SERVER_URI + "/graphql",
 credentials: "include",
 fetch,
});

export const afterwareLink = new ApolloLink((operation, forward) => {
 const fowareds = forward(operation);
 if (!fowareds.map) return fowareds;
 return fowareds.map((response) => {
  const context = operation.getContext();
  const {
   response: { headers, body, status },
  } = context;

  if (headers) {
   // if (headers.get("Refresh")) {
   //     logSend({
   //         level: "info",
   //         message: { refresh: headers.get("Refresh"), version },
   //         about: "reload with cache",
   //     });
   //     const newUrl = updateURLParameter(
   //         location.href,
   //         "ver",
   //         new Date().valueOf().toString()
   //     );
   //     location.href = newUrl;
   // }
   const bookerSession = headers.get("bookerSession");
   // const approachStoreOwnerId = SessionFisrtLocalStorage.getItem(
   //     LSK.approachStoreOwnerId
   // );
   if (bookerSession) {
    localStorage.setItem("bsk", bookerSession);
   }
  }

  return response;
 });
});

export const splitLink = split(
 ({ query, operationName }) => {
  const definition = getMainDefinition(query);
  if (["track"].includes(operationName)) return true;
  return (
   definition.kind === "OperationDefinition" &&
   definition.operation === "subscription"
  );
 },
 wsLink,
 fileUploadLink as any
);

export const WSclient = new ApolloClient({
 link: wsLink,
 cache,
});
