//  3글자 마다 ,붙여줌
export const autoComma = (str: string | number = ""): string => {
 if (typeof str === "number") return autoComma(str.toString());
 let t = `${str}`;
 const comma = /,/g;
 t = t.replace(comma, "");
 const x = t.split(".");
 let x1 = x[0];
 const x2 = x.length > 1 ? `.${x[1]}` : "";
 const rgx = /(\d+)(\d{3})/;
 while (rgx.test(x1)) {
  x1 = x1.replace(rgx, "$1" + "," + "$2");
 }
 return x1 + x2;
};

const inlineElements = [
 "span",
 "br",
 "b",
 "i",
 "strong",
 "em",
 "a",
 "abbr",
 "acronym",
 "cite",
 "code",
 "dfn",
 "kbd",
 "samp",
 "time",
 "var",
 "\n",
];

export function gcd(a: number, b: number): number {
 return b === 0 ? a : gcd(b, a % b);
}

export function getFormattedRatio(width: number, height: number): string {
 if (height === 0) {
  throw new Error("Height cannot be zero");
 }
 const ratio = width / height;
 const roundedRatio = Math.round(ratio * 10) / 10;

 // Convert the rounded ratio back to "x:y" format
 let formattedWidth = roundedRatio * 10;
 let formattedHeight = 10;

 const divisor = gcd(formattedWidth, formattedHeight);
 formattedWidth = formattedWidth / divisor;
 formattedHeight = formattedHeight / divisor;

 return `${formattedWidth}(가로):${formattedHeight}(세로)`;
}

export function getRoundedRatio(width: number, height: number) {
 if (height === 0) {
  throw new Error("Height cannot be zero");
 }

 const ratio = width / height;
 const roundedRatio = Math.round(ratio * 10) / 10;
 return parseFloat(roundedRatio.toFixed(1));
}

//  전화번호 형식에 맞추어 - 붙임
const autoHypenPhone = (str: string = ""): string => {
 const inStr = str.replace(/[^0-9]/g, "");
 let tmp = "";
 if (inStr.length < 4) {
  return inStr;
 }

 if (inStr.length < 7) {
  tmp += inStr.substr(0, 3);
  tmp += "-";
  tmp += inStr.substr(3);
  return tmp;
 }

 if (inStr.length === 8) {
  tmp += inStr.substr(0, 4);
  tmp += "-";
  tmp += inStr.substr(4);
  return tmp;
 }

 if (inStr.length < 11) {
  tmp += inStr.substr(0, 3);
  tmp += "-";
  tmp += inStr.substr(3, 3);
  tmp += "-";
  tmp += inStr.substr(6);
  return tmp;
 }
 if (inStr.length >= 11) {
  tmp += inStr.substr(0, 3);
  tmp += "-";
  tmp += inStr.substr(3, 4);
  tmp += "-";
  tmp += inStr.substr(7);
  return tmp;
 }

 return inStr;
};
export const autoHypen = autoHypenPhone;

export function escapeRegExp(string: string) {
 return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function replaceAll(str: string, find: string, replace: string) {
 return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

export const removeChar = (full: string, char: string) => {
 return replaceAll(full, char, "");
};

export function clamp(value: number, min: number, max: number): number {
 return Math.min(Math.max(value, min), max);
}

export function extractYouTubeVideoID(url: string): string | null {
 const regex =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\s*|.*\/v\/|.*\/e(?:mbed)?)?\s*\/|\s*youtu\.be\/|youtube:\s*\/{2})((w|-){11})(?=\s*$|\s*[^\/\n\s])/;
 const match = url.match(regex);
 return match ? match[1] : null;
}

export function extractMetaTagContent(input: string): string {
 const metaTagPattern = /<meta[^>]*content="([^"]*)"/i;
 const match = input.trim().match(metaTagPattern);

 if (match && match[1]) {
  return match[1];
 } else return input;
}

export function formatKoreanBankAccountNumber(accountNumber: string) {
 return accountNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
}
