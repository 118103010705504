import {
 CellType,
 FcellFragment,
 FgroupFragment,
} from "../../app/apollo/type/graphql";
import { enumCheck } from "../../utils/enum";
import Dev from "../devTools/Dev";
import { IconList } from "./IconList";
import { Info } from "./Info";
import { InfoList } from "./Infolist";
import { MapCell } from "./MapCell";
import { Square } from "./Square";
import { Vertical } from "./Vertical";

interface IProp {
 cell: FcellFragment;
}

export const Cell: React.FC<IProp> = ({ cell }) => {
 const typeIs = enumCheck(cell.type, CellType);

 if (typeIs.Square) return <Square cell={cell} />;

 if (typeIs.Info) return <InfoList cell={cell} />;

 if (typeIs.Verti) return <Vertical cell={cell} />;

 if (typeIs.PageInfo) return <Info cell={cell} />;

 if (typeIs.Map) return <MapCell cell={cell} />;

 if (typeIs.InfoPageList) return <IconList cell={cell} />;

 return <Dev>no type found</Dev>;
};
