import { boolToString, TMaybe, strBoolean, TboolStr } from "./boolean";
import { jsonSafePrae } from "./safeParse";

export class MemoryStorage implements Storage {
 clear(): void {
  this.map.clear();
 }
 key(index: number): string | null {
  //@ts-ignore
  return [...this.map.entries()]?.[index]?.[1];
 }
 get length() {
  return this.map.size;
 }

 private static instance: MemoryStorage;
 private constructor() {}

 private map = new Map();

 public removeItem(key: string) {
  this.map.delete(key);
 }

 public setItem(key: string, value: string) {
  this.map.set(key, value);
 }

 public getItem(key: string) {
  return this.map.get(key);
 }

 public static get Instance() {
  if (this.instance != null) return this.instance;
  return new MemoryStorage();
 }
}

export class StorageAddition implements Storage {
 key(index: number): string | null {
  return this.storage.key(index);
 }
 clear(): void {
  return this.storage.clear();
 }
 getItem(key: string): string | null {
  return this.storage.getItem(key);
 }
 removeItem(key: string): void {
  return this.storage.removeItem(key);
 }
 get length() {
  return this.storage.length;
 }
 setItem(key: string, value: string): void {
  this.storage.setItem(key, value);
 }

 readonly storage: Storage;
 constructor(storage: Storage) {
  this.storage = storage;
 }

 push(key: string, value: string | number | Object) {
  try {
   let arr = this.getLocalObj(key) || [];
   if (!Array.isArray(arr)) arr = [];
   arr.push(value);
   this.set(key, arr);
  } catch (e) {}
 }

 //Array
 include(key: string, value: string | number | Object) {
  try {
   const arr = this.getLocalObj(key);
   console.log("arr", arr);
   if (!Array.isArray(arr)) return false;
   return arr.includes(value);
  } catch (e) {}
 }

 //Array
 filter(key: string, value: string | number | Object) {
  try {
   const arr = this.getLocalObj(key);
   if (!Array.isArray(arr)) return;
   const nextArr = arr.filter((val) => val !== value);
   this.set(key, nextArr);
  } catch (e) {}
 }

 set(key: string, value: string | number | Object): void {
  try {
   let _value = value;

   if (typeof value === "number") {
    _value = value.toString();
   }
   if (typeof value === "boolean") {
    _value = boolToString(value);
   }

   if (typeof value === "object") {
    try {
     _value = JSON.stringify(value);
    } catch (e) {
     this.storage.removeItem(key);
     console.error("LocalManager::saveLocal:stringFyFailed");
     console.error(e);
    }
   }

   if (typeof _value === "string") this.storage.setItem(key, _value);
  } catch (e) {
   console.error("LocalManager::saveLocal:");
   console.error(e);
  }
 }

 //미완성
 private get<T>(key: string): TMaybe<T> {
  const val = this.storage.getItem(key);
  console.log("val----", val);
  console.log(typeof val);
  const jsonTypeCheck = jsonSafePrae(val || "", false);

  if (typeof val === "undefined" || val === null) return undefined;
  if (typeof jsonTypeCheck === "object") {
   return this.getLocalObj(key);
  }
 }
 getBool(key: string): boolean {
  const val = this.storage.getItem(key);
  if (typeof val === "undefined" || val === null) return false;
  return strBoolean(val as TboolStr);
 }

 getLocalObj(key: string): any {
  let result;
  try {
   const value = this.storage.getItem(key) || "";

   try {
    result = JSON.parse(value);
   } catch (e) {
    this.storage.removeItem(key);
    console.error("LocalManager::getLocalOj:parseFailed");
    console.error(e);
   }
  } catch (e) {
   console.error("LocalManager::getLocalOj:");
   console.error(e);
  }

  return result;
 }

 getLocal(key: string): TMaybe<string> {
  try {
   const item = this.storage.getItem(key) || "";
   return item;
  } catch (e) {
   console.error("LocalManager::getLocal:");
   console.error(e);
  }
 }

 getNum(key: string): TMaybe<number> {
  try {
   const item = this.storage.getItem(key) || "";
   return parseInt(item);
  } catch (e) {
   console.error(e);
   return;
  }
 }
}

export const iamTopWindow =
 typeof window !== "undefined" && window.top === window.self;

export const LocalStorage = (() => {
 if (iamTopWindow) {
  return new StorageAddition(localStorage);
 } else {
  return new StorageAddition(MemoryStorage.Instance);
 }
})();
export const SessionStroage = (() => {
 // iamTopWindow
 if (iamTopWindow) {
  return new StorageAddition(sessionStorage);
 } else {
  return new StorageAddition(MemoryStorage.Instance);
 }
})();
