import { AppContext, useAppContextProvider } from "./hook/useApp.context";
import { RouterProvider } from "react-router-dom";
import { Router } from "./page/router";
import PromptModal from "./components/systemModal/PromptModal";
import AlertModal from "./components/systemModal/AlertModal";
import { AlertModalDetail } from "./components/ItemDetailModal";
import { RoomNumberPopModal } from "./components/RoomNumberPop.modal";
import { useMemo, useLayoutEffect } from "react";
import { colorInlineObjectGenerater } from "./utils/cssvar";
import "aos/dist/aos.css";
import AOS from "aos";

export const App = () => {
 const contextValue = useAppContextProvider({});
 const colorStyle = useMemo(() => {
  return colorInlineObjectGenerater("#264186");
 }, []);

 useLayoutEffect(() => {
  const Colors = Object.entries(colorStyle);
  for (const [key, val] of Colors) {
   document.documentElement.style.setProperty(key, val);
  }
  return () => {};
 }, []);

 useLayoutEffect(() => {
  // SSR 캐시가 완전히 생성되지 않은 상태라면 init이 너무 빠를수도 있다.
  // 위 현상을 방지하려면... 블럭들의 높이가 어느정도 고정되어야한다.
  // 그러나 로드되기전까지 블럭들의 높이를 가늠하긴 어려운 문제다.
  // refresh 되기전까지 offset을 엄청나게 높여두는것도 좋은방법이지 않을까?

  AOS.init({ duration: 1000 });

  const body = document.getElementsByTagName("body");
  const observer = new ResizeObserver((entries) => {
   for (let entry of entries) {
    AOS.refresh();
   }
  });

  if (body.item(0)) {
   observer.observe(body.item(0)!);
  }

  return () => observer.disconnect();
 }, []);

 return (
  <AppContext.Provider value={contextValue}>
   <RouterProvider router={Router} />
   {contextValue.promptModalHook.isOpen && (
    <PromptModal modalHook={contextValue.promptModalHook} />
   )}
   {contextValue.confirmModalHook.isOpen && (
    <PromptModal modalHook={contextValue.promptModalHook} />
   )}
   {contextValue.alertModalHook.isOpen && (
    <AlertModal modalHook={contextValue.alertModalHook} />
   )}
   {contextValue.itemDetailModalHook.isOpen && (
    <AlertModalDetail modalHook={contextValue.itemDetailModalHook} />
   )}
  </AppContext.Provider>
 );
};
