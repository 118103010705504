import { toast } from "react-toastify";
import foucsById, { foucsById2 } from "./dom/focus";

type SkipFn = () => boolean;
type TSkip = boolean | SkipFn;
export type ValidateNode = {
 value: any;
 failMsg?: string;
 id?: string;
 failFn?: () => void;
 skip?: TSkip;
};

const validate = (nodes: ValidateNode[]): boolean => {
 return nodes.every((node) => {
  if (!node.value) {
   if (node.failMsg) alert(node.failMsg);
   if (node.id) foucsById(node.id);
   return false;
  }

  return true;
 });
};
function isFunction(functionToCheck: any) {
 return (
  functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
 );
}

export class Validater {
 nodes: ValidateNode[];

 public validate = (checks?: number[], silent?: boolean): boolean => {
  const targetNodes = checks?.map((num) => this.nodes[num]);
  let nodes = targetNodes || this.nodes;

  return nodes.every((node) => {
   if (typeof node.skip === "function" && node.skip()) return true;
   else if (node.skip) return true;
   let value = node.value;
   if (isFunction(node.value)) {
    value = node.value();
   }
   if (!value) {
    if (silent) return false;
    if (node.failMsg) toast(node.failMsg);
    if (node.id) foucsById2(node.id);
    if (node.failFn) node.failFn();
    return false;
   }

   return true;
  });
 };

 public checkAt(id: string) {
  const nodeIndex = this.nodes.findIndex((nd) => nd.id === id);
  return this.validate([nodeIndex]);
 }
 public withValidate = (onScuess: () => void) => () => {
  if (this.validate()) onScuess();
  return () => {};
 };

 public ok() {}

 constructor(nodes: ValidateNode[]) {
  this.nodes = nodes;
 }
}

export const isFn = (foo: any) => typeof foo === "function";

export default validate;
