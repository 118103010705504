import React, { useEffect } from "react";
import { useUpdateComponent } from "../../hook/useUpdateComponent";
import classNames from "classnames";
import { IS_DEV } from "../../data/env.short";
import { IVscodeLinkPath, getVsCodeLink } from "../../utils/date";

export interface IDevProp {
 height?: string;
 label?: string;
 defaultVisible?: boolean;
 children?: any;
 onClick?: (e: any) => void;
 className?: string;
 visible?: boolean;
 vsCodeLink?: IVscodeLinkPath;
}

export const Dev: React.FC<IDevProp> = ({
 label,
 height,
 defaultVisible,
 visible,
 vsCodeLink,
 className,
 ...props
}) => {
 // usePubSubEvent(PubSubEventType.DevToggled, () => {
 //     updateComponent();
 // });

 if (!IS_DEV) return null;

 return (
  <div
   className={classNames("JDdev", className)}
   onClickCapture={() => {
    if (vsCodeLink) window.open(getVsCodeLink(vsCodeLink));
   }}
   style={{
    maxHeight: height,
    overflow: "auto",
    background: "blue",
    textAlign: "left",
    fontSize: "10px",
    color: "white",
   }}
   {...props}
  />
 );
};

export default Dev;
