import { getFromUrl } from "../utils/getFromUrl";
import {
 LocalStorage,
 iamTopWindow,
} from "../utils/localstorage/StorageAddition";
import { LabelValuePair } from "./type";
const langData = {
 PM: {
  ko: "오후",
  ja: "午後",
  en: "PM",
  zh: "下午",
  zhTW: "下午",
  zhHK: "下午",
  th: "บ่าย",
  vi: "Chiều",
  tl: "Hapon",
  ms: "Petang",
  id: "Sore",
  hi: "अपराह्न",
  fr: "PM",
  de: "PM",
  enGB: "PM",
  enCA: "PM",
  mn: "Үдээс хойш",
  it: "PM",
 },
 AM: {
  ko: "오전",
  ja: "午前",
  en: "AM",
  zh: "上午",
  zhTW: "上午",
  zhHK: "上午",
  th: "เช้า",
  vi: "Sáng",
  tl: "Umaga",
  ms: "Pagi",
  id: "Pagi",
  hi: "पूर्वाह्न",
  fr: "AM",
  de: "AM",
  enGB: "AM",
  enCA: "AM",
  mn: "Үдээс өмнө",
  it: "AM",
 },
 "Start Now": {
  ko: "지금 시작하기",
  en: "Start Now",
  zh: "立即开始",
  zhTW: "立即開始",
  zhHK: "立即開始",
  th: "เริ่มเลย",
  vi: "Bắt đầu ngay",
  tl: "Simulan Ngayon",
  ms: "Mula Sekarang",
  id: "Mulai Sekarang",
  ja: "今すぐ開始",
  hi: "अभी शुरू करें",
  fr: "Commencez maintenant",
  de: "Jetzt starten",
  enGB: "Start Now",
  enCA: "Start Now",
  mn: "Одоо эхлэх",
  it: "Inizia ora",
 },
 Home: {
  ko: "메인",
  en: "Home",
  zh: "首页",
  zhTW: "首頁",
  zhHK: "首頁",
  th: "หน้าหลัก",
  vi: "Trang chủ",
  tl: "Bahay",
  ms: "Laman Utama",
  id: "Beranda",
  ja: "ホーム",
  hi: "होम",
  fr: "Accueil",
  de: "Startseite",
  enGB: "Home",
  enCA: "Home",
  mn: "Нүүр",
  it: "Home",
 },
 Chat: {
  ko: "채팅",
  en: "Chat",
  zh: "聊天",
  zhTW: "聊天",
  zhHK: "聊天",
  th: "แชท",
  vi: "Trò chuyện",
  tl: "Chat",
  ms: "Sembang",
  id: "Obrolan",
  ja: "チャット",
  hi: "चैट",
  fr: "Chat",
  de: "Chat",
  enGB: "Chat",
  enCA: "Chat",
  mn: "Чат",
  it: "Chat",
 },
 Room: {
  ko: "방",
  en: "Room",
  zh: "房间",
  zhTW: "房間",
  zhHK: "房間",
  th: "ห้อง",
  vi: "Phòng",
  tl: "Kwarto",
  ms: "Bilik",
  id: "Kamar",
  ja: "部屋",
  hi: "कमरा",
  fr: "Chambre",
  de: "Zimmer",
  enGB: "Room",
  enCA: "Room",
  mn: "Өрөө",
  it: "Stanza",
 },
 WELLCOME: {
  ko: "환영합니다!",
  en: "WELLCOME",
  zh: "欢迎",
  zhTW: "歡迎",
  zhHK: "歡迎",
  th: "ยินดีต้อนรับ",
  vi: "Chào mừng",
  tl: "Maligayang pagdating",
  ms: "Selamat datang",
  id: "Selamat datang",
  ja: "ようこそ",
  hi: "स्वागत हे",
  fr: "Bienvenue",
  de: "Willkommen",
  enGB: "WELLCOME",
  enCA: "WELLCOME",
  mn: "Тавтай морилно уу",
  it: "Benvenuto",
 },
 RoomPopIntro: {
  ko: "고객님의 정보 보호를 위해 객실 번호 입력 후 사용하세요",
  en: "For your information protection, please enter the room number",
  ja: "お客様の情報保護のため、部屋番号を入力してください",
  zh: "为保护您的信息，请输入房间号",
  zhTW: "為了保護您的信息，請輸入房間號",
  zhHK: "為了保護您的信息，請輸入房間號",
  th: "เพื่อปกป้องข้อมูลของคุณ กรุณากรอกหมายเลขห้อง",
  vi: "Để bảo vệ thông tin của bạn, vui lòng nhập số phòng",
  tl: "Para sa proteksyon ng iyong impormasyon, mangyaring ipasok ang numero ng silid",
  ms: "Untuk melindungi maklumat anda, sila masukkan nombor bilik",
  id: "Untuk melindungi informasi Anda, silakan masukkan nomor kamar",
  hi: "आपकी जानकारी की सुरक्षा के लिए कृपया कमरे का नंबर दर्ज करें",
  fr: "Pour protéger vos informations, veuillez entrer le numéro de la chambre",
  de: "Zum Schutz Ihrer Informationen geben Sie bitte die Zimmernummer ein",
  enGB: "For your information protection, please enter the room number",
  enCA: "For your information protection, please enter the room number",
  mn: "Таны мэдээллийг хамгаалах үүднээс өрөөний дугаарыг оруулна уу",
  it: "Per proteggere le tue informazioni, inserisci il numero della stanza",
 },
 RoomPopIntroExample: {
  ko: "예) 535호 → 0535 입력",
  en: "e.g., Room 535 → Enter 0535",
  ja: "例）535号室 → 0535を入力",
  zh: "例如：房间535 → 输入0535",
  zhTW: "例如：房間535 → 輸入0535",
  zhHK: "例如：房間535 → 輸入0535",
  th: "ตัวอย่าง: ห้อง 535 → กรอก 0535",
  vi: "Ví dụ: Phòng 535 → Nhập 0535",
  tl: "Halimbawa: Silid 535 → Ipasok ang 0535",
  ms: "Contoh: Bilik 535 → Masukkan 0535",
  id: "Contoh: Kamar 535 → Masukkan 0535",
  hi: "उदा।, कमरा 535 → दर्ज करें 0535",
  fr: "par exemple, Chambre 535 → Entrez 0535",
  de: "z.B. Zimmer 535 → 0535 eingeben",
  enGB: "e.g., Room 535 → Enter 0535",
  enCA: "e.g., Room 535 → Enter 0535",
  mn: "Жишээ нь: Өрөө 535 → 0535 оруулах",
  it: "esempio: Stanza 535 → Inserisci 0535",
 },
 Submit: {
  ko: "완료",
  en: "ENTER",
  ja: "エンター",
  zh: "提交",
  zhTW: "提交",
  zhHK: "提交",
  th: "เข้าสู่ระบบ",
  vi: "NHẬP",
  tl: "IPASOK",
  ms: "MASUK",
  id: "MASUK",
  hi: "जमा करें",
  fr: "ENTRER",
  de: "EINGEBEN",
  enGB: "ENTER",
  enCA: "ENTER",
  mn: "ОРУУЛАХ",
  it: "INVIO",
 },
 Language: {
  ko: "언어",
  en: "Language",
  ja: "言語",
  zh: "语言",
  zhTW: "語言",
  zhHK: "語言",
  th: "ภาษา",
  vi: "Ngôn ngữ",
  tl: "Wika",
  ms: "Bahasa",
  id: "Bahasa",
  hi: "भाषा",
  fr: "Langue",
  de: "Sprache",
  enGB: "Language",
  enCA: "Language",
  mn: "Хэл",
  it: "Lingua",
 },
 ChatStartEx: {
  ko: `고객님의 정보 보호를 위해 객실 번호 입력 후 사용하세요\n예) 535호 → 0535 입력`,
  ja: `고객님의 정보 보호를 위해 객실 번호 입력 후 사용하세요\n예) 535호 → 0535 입력`,
  en: `For your information protection, please enter the room number\nExample: Room 535 → Enter 0535`,
  zh: `为了保护您的信息，请输入房间号\n例如：房间 535 → 输入 0535`,
  zhTW: `為了保護您的信息，請輸入房間號\n例如：房間 535 → 輸入 0535`,
  zhHK: `為了保護您的信息，請輸入房間號\n例如：房間 535 → 輸入 0535`,
  th: `เพื่อปกป้องข้อมูลของคุณ กรุณากรอกหมายเลขห้อง\nตัวอย่าง: ห้อง 535 → กรอก 0535`,
  vi: `Để bảo vệ thông tin của bạn, vui lòng nhập số phòng\nVí dụ: Phòng 535 → Nhập 0535`,
  tl: `Para sa proteksyon ng iyong impormasyon, mangyaring ipasok ang numero ng silid\nHalimbawa: Silid 535 → Ipasok ang 0535`,
  ms: `Untuk melindungi maklumat anda, sila masukkan nombor bilik\nContoh: Bilik 535 → Masukkan 0535`,
  id: `Untuk melindungi informasi Anda, silakan masukkan nomor kamar\nContoh: Kamar 535 → Masukkan 0535`,
  hi: `आपकी जानकारी की सुरक्षा के लिए कृपया कमरे का नंबर दर्ज करें\nउदाहरण: कमरा 535 → दर्ज करें 0535`,
  fr: `Pour protéger vos informations, veuillez entrer le numéro de la chambre\nExemple: Chambre 535 → Entrez 0535`,
  de: `Zum Schutz Ihrer Informationen geben Sie bitte die Zimmernummer ein\nBeispiel: Zimmer 535 → 0535 eingeben`,
  enGB: `For your information protection, please enter the room number\nExample: Room 535 → Enter 0535`,
  enCA: `For your information protection, please enter the room number\nExample: Room 535 → Enter 0535`,
  mn: `Таны мэдээллийг хамгаалах үүднээс өрөөний дугаарыг оруулна уу\nЖишээ нь: Өрөө 535 → 0535 оруулах`,
  it: `Per proteggere le tue informazioni, inserisci il numero della stanza\nEsempio: Stanza 535 → Inserisci 0535`,
 },
 Language_Desc: {
  ko: "표기 언어를 변경합니다.",
  en: "Please select the language you want to use",
  ja: "表示言語を変更します。",
  zh: "更改显示语言",
  th: "เปลี่ยนภาษาที่แสดง",
  vi: "Thay đổi ngôn ngữ hiển thị",
  tl: "Baguhin ang wika ng display",
  ms: "Tukar bahasa paparan",
  id: "Ganti bahasa tampilan",
  hi: "दिखाई जाने वाली भाषा बदलें",
  fr: "Modifier la langue affichée",
  de: "Ändere die angezeigte Sprache",
  mn: "Харуулах хэлийг сонгоно уу",
  it: "Si prega di selezionare la lingua che si desidera utilizzare",
 },
};

export const L: Record<keyof typeof langData, string> = {} as any;
export const LagnList = [
 "ko", // Korean
 "en", // English
 "zh", // Chinese
 "th", // Thai
 "vi", // Vietnamese
 "ms", // Malay
 "id", // Indonesian
 "ja", // Japanese
 "hi", // Hindi
 "fr", // French
 "de", // German
 "it", // Italian
];

export const LangPair = {
 ko: "한국어",
 en: "English",
 zh: "中文",
 th: "ภาษาไทย",
 vi: "Tiếng Việt",
 tl: "Filipino",
 ms: "Bahasa Melayu",
 id: "Bahasa Indonesia",
 ja: "日本語",
 hi: "हिन्दी",
 fr: "Français",
 de: "Deutsch",
 mn: "Монгол",
 it: "Italiano",
};

export const LangOps: LabelValuePair[] = LagnList?.map((lang) => {
 return {
  label: LangPair[lang as keyof typeof LangPair],
  value: lang,
 };
});

export const awsLangCodeMap: Map<string, string> = new Map([
 ["ko", "ko-KR"],
 ["en", "en-US"], // English (United States)
 ["zh", "cmn-CN"], // Mandarin Chinese (Simplified, China)
 ["zhTW", "cmn-TW"], // Taiwanese Mandarin (Traditional, Taiwan)
 ["zhHK", "yue-HK"], // Cantonese (Traditional, Hong Kong)
 ["th", "th-TH"], // Thai (Thailand)
 ["vi", "vi-VN"], // Vietnamese (Vietnam)
 ["tl", "fil-PH"], // Filipino (Philippines)
 ["ms", "ms-MY"], // Malay (Malaysia)
 ["id", "id-ID"], // Indonesian (Indonesia)
 ["ja", "ja-JP"], // Japanese (Japan)
 ["hi", "hi-IN"], // Hindi (India)
 ["fr", "fr-FR"], // French (France)
 ["de", "de-DE"], // German (Germany)
 ["enGB", "enGB"], // British English (United Kingdom)
 ["enCA", "enCA"], // Canadian English (Canada)
 ["mn", "mn-MN"], // Mongolian (Mongolia)
 ["it", "it-IT"], // Italian (Italy)
]);

const reversedMap = new Map<string, string>(
 Array.from(awsLangCodeMap, (a) => a.reverse() as any)
);

export const CACHE = {
 langCode: LocalStorage.getItem("lang") || "",
};
export const getLangCode = (_lang: string) => {
 //  const urlForceLang = getFromUrl("lang");
 if (!iamTopWindow) return "ko";
 //  if (urlForceLang) return urlForceLang;
 if (CACHE.langCode) return CACHE.langCode;
 const lang = _lang;
 let langCode = "en";
 if (LagnList.includes(lang)) {
  langCode = lang;
 } else if (reversedMap.get(lang)) {
  langCode = reversedMap.get(lang) as any;
 }
 return langCode;
};

export const LangSet = (lang: string) => {
 const langCode = getLangCode(lang);

 const keys = Object.keys(langData);
 keys.forEach((key) => {
  //@ts-ignore
  L[key] = langData[key][langCode] || langData[key]["en"];
 });
 console.log("@@@L", { L, langCode });
};
export default langData;

export const unspportedAwsLangCodes = [
 "ms",
 "ms-MY",
 "th",
 "th-TH",
 "vi",
 "vi-VN",
 "tl",
 "fil-PH",
 "id",
 "mn",
];
