import { SVGProps } from "react";

export const DoorIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
 return (
  <svg
   xmlns="http://www.w3.org/2000/svg"
   width="20"
   height="20"
   viewBox="0 0 20 20"
   fill="none"
   {...props}
  >
   <path
    d="M2.5 17.5V15.8333H4.16667V4.16667C4.16667 3.70833 4.33 3.31611 4.65667 2.99C4.98333 2.66389 5.37556 2.50056 5.83333 2.5H14.1667C14.625 2.5 15.0175 2.66333 15.3442 2.99C15.6708 3.31667 15.8339 3.70889 15.8333 4.16667V15.8333H17.5V17.5H2.5ZM12.5 15.8333H14.1667V4.16667H10.4167V3.25C11.0278 3.36111 11.5278 3.64583 11.9167 4.10417C12.3056 4.5625 12.5 5.09722 12.5 5.70833V15.8333ZM9.16667 10.8333C9.40278 10.8333 9.60083 10.7533 9.76083 10.5933C9.92083 10.4333 10.0006 10.2356 10 10C9.99944 9.76444 9.91944 9.56667 9.76 9.40667C9.60056 9.24667 9.40278 9.16667 9.16667 9.16667C8.93056 9.16667 8.73278 9.24667 8.57333 9.40667C8.41389 9.56667 8.33389 9.76444 8.33333 10C8.33278 10.2356 8.41278 10.4336 8.57333 10.5942C8.73389 10.7547 8.93167 10.8344 9.16667 10.8333Z"
    fill="#264186"
   />
  </svg>
 );
};
