import { focusWithScroll } from './parentScroll';

export const foucsById = (id: string) => {
  const ele = document.getElementById(id);
  focusWithScroll(ele);
};
export default foucsById;

export const foucsById2 = (id: string) => {
  const ele = document.getElementById(id);
  ele?.focus();
  ele?.scrollIntoView({ block: 'center', inline: 'center' });
};
