export function jsonSafePrae<ErrReturn = any>(
  jsonString: string,
  err?: ErrReturn
) {
  try {
    const result = JSON.parse(jsonString);
    return result;
  } catch (e) {
    console.log('jsonSafePrae', e);
    if (typeof err !== 'undefined') return err;
    return {};
  }
}

export function jsonSafeStringFy(json: Object) {
  try {
    const result = JSON.stringify(
      json,
      json instanceof Error ? Object.getOwnPropertyNames(json) : undefined
    );
    return result;
  } catch (e) {
    return '';
  }
}
