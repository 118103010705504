import { useEffect, useMemo, useState } from "react";
import { FfileFragment } from "../app/apollo/type/graphql";
import { blurHashToDataURL } from "../utils/blurhash";

interface IProp
 extends React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
 > {
 file?: FfileFragment | null;
 size?: string | number;
 defaultSrc?: string;
 bg?: boolean;
}

export const Photo: React.FC<IProp> = ({
 file,
 src,
 size,
 bg,
 defaultSrc,
 ...props
}) => {
 const [loaded, setLoaded] = useState(false);

 const originalSrc = src || file?.uri;
 const hashblur = file?.blur;

 const sizedUrl =
  originalSrc && size ? getResize(originalSrc, size) : originalSrc;

 const dataURL = useMemo(
  () => (hashblur ? blurHashToDataURL(hashblur, 32, 32) : undefined),
  [hashblur]
 ); // Adjust the width and height as needed
 const Tag = bg ? "div" : "img";
 return (
  <>
   {dataURL && !loaded && <img src={dataURL} {...props} />}
   <Tag
    onLoad={() => setLoaded(true)}
    onError={(e) => {
     if (!originalSrc) return;
     if (e.currentTarget.src !== originalSrc)
      e.currentTarget.src = originalSrc!;
    }}
    {...props}
    style={
     bg
      ? {
         backgroundSize: "cover",
         backgroundImage: sizedUrl || defaultSrc,
         backgroundPosition: "center center",
        }
      : undefined
    }
    src={sizedUrl || defaultSrc}
   />
  </>
 );
};

function getResize(url: string, size: string | number) {
 // This regex matches the last part of the URL that might include a pattern like '---1500'
 // and might end with a file extension like '.jpg'. It captures the base name and the extension separately.
 const regex = /(?:---\d+)?(\.[^.\/]+)?$/;

 // Replace the matched part with '---{number}' and keep the original extension if it exists.
 // If the URL does not end with an extension, it will just append '---{number}'.
 return url.replace(regex, "---" + size + "$1");
}
