import { useEffect, useState } from "react";

type TDefaultModal<T> = {
 defaultOpen?: boolean;
 defaultInfo?: T;
};

export type TUseModal<T = any> = {
 info: T | undefined;
 isOpen: boolean;
 setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
 close: () => void;
 open: (info?: T | undefined) => void;
 toggle: () => void;
 isOpenStart: boolean;
};
export const useModal = <T extends any>({
 defaultOpen = false,
 defaultInfo,
}: TDefaultModal<T> = {}) => {
 const [noOpenAnime, setNoOpenAnime] = useState(false);
 const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
 const [isOpenStart, setIsOpenStart] = useState<boolean>(defaultOpen);
 const [info, setInfo] = useState<T | undefined>(defaultInfo);

 const open = (info?: T) => {
  setInfo(info);
  setNoOpenAnime(false);
  setIsOpenStart(true);
 };

 const openImmediate = (info?: T) => {
  setInfo(info);
  setIsOpen(true);
  setNoOpenAnime(true);
  setIsOpenStart(true);
 };

 const close = () => {
  setNoOpenAnime(false);
  setIsOpenStart(false);
 };

 const closeImmeditate = () => {
  setNoOpenAnime(true);
  setIsOpenStart(false);
  setIsOpen(false);
 };

 const toggle = () => {
  setIsOpenStart(!isOpen);
 };

 //  useEffect(() => {
 //   setTimeout(
 //    () => {
 //     setIsOpen(isOpenStart);
 //    },
 //    isOpenStart ? 0 : 300
 //   );
 //  }, [isOpenStart]);

 return {
  noOpenAnime,
  openImmediate,
  closeImmeditate,
  info,
  isOpen,
  isOpenStart,
  setIsOpen,
  close,
  open,
  toggle,
 };
};
