import { Fragment, useContext } from "react";
import { FgroupFragment } from "../../app/apollo/type/graphql";
import { Cell } from "./Cell";
import { AppContext } from "../../hook/useApp.context";
import { Transition } from "@headlessui/react";

interface IProp {
 group: FgroupFragment;
}

export const Group: React.FC<IProp> = ({ group }) => {
 const { lang } = useContext(AppContext);
 return (
  <div>
   {group.name?.ko && (
    <h6
     data-aos="fade-up"
     className="font-semibold   my-[5.56vw] md:my-[42.67px] text-[3.33vw] md:text-[25.6px]"
    >
     {lang(group.name)}
    </h6>
   )}
   <div
    data-aos="fade-up"
    className="grid-cols-2 grid gap-[3.33vw] md:gap-[25.6px]"
   >
    {group.cells?.map((cell) => {
     return <Cell key={cell.key} cell={cell} />;
    })}
   </div>
  </div>
 );
};
