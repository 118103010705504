import { SpeakerWaveIcon } from "@heroicons/react/20/solid";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../../hook/useApp.context";
import { FcellFragment } from "../../app/apollo/type/graphql";
import { getSppech } from "../../utils/speech";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { CellWrap } from "./CellWrap";
import { stopAllSpeaker } from "../../utils";
import { unspportedAwsLangCodes } from "../../data/lang.static";

interface IInfoProp {
 cell: FcellFragment;
}

export const Info: React.FC<IInfoProp> = ({ cell }) => {
 const { lang, langCode } = useContext(AppContext);
 const speakerRef = useRef<SVGSVGElement>(null);
 const descArray: string[] = lang(cell.desc)?.split("\n") || [];
 return (
  <CellWrap cell={cell} className="col-span-2">
   <div className="w-full py-[2.78vw] md:py-[21.33px] justify-between items-center flex">
    <div className="text-[4.44vw] md:text-[34.13px] font-bold !leading-none">
     {lang(cell.name)}
    </div>
    {!unspportedAwsLangCodes.includes(cell.key) && (
     <SpeakerWaveIcon
      ref={speakerRef}
      onClick={() => {
       const audio = document.getElementById(
        cell.key + "Speech"
       ) as HTMLAudioElement;
       if (audio) {
        if (!audio.paused) {
         audio.pause();
        } else {
         audio.play();
        }
       }
      }}
      className={twMerge(
       classNames(
        "text-white cursor-pointer p-[1.1vw] md:p-2 w-[6.67vw] md:w-[51.2px] h-[6.67vw] md:h-[51.2px] relative bg-blue-900 rounded-[1.11vw] md:rounded-[8.53px]"
        //    {
        //    }
       )
      )}
     />
    )}
   </div>
   <audio
    className="Speaker"
    onPlayCapture={(e) => {
     stopAllSpeaker(e.currentTarget as HTMLAudioElement);
    }}
    onPlay={(e) => {
     if (speakerRef.current?.style) {
      speakerRef.current?.classList.remove("bg-blue-900");
      speakerRef.current?.classList.add("bg-yellow-500");
     }
    }}
    onPause={() => {
     if (speakerRef.current?.style) {
      speakerRef.current?.classList.add("bg-blue-900");
      speakerRef.current?.classList.remove("bg-yellow-500");
     }
    }}
    id={cell.key + "Speech"}
   >
    <source src={getSppech(cell.key, langCode || "ko")} />
   </audio>
   <ul className="text-[3.89vw] md:text-[29.87px] list-none">
    {descArray?.map((desc, index) => {
     return (
      <li
       className="flex  text-[3.89vw] md:text-[29.87px] gap-[1.11vw] md:gap-[4.27px]"
       key={index + "Desc"}
      >
       <span className="mt-[2.22vw] md:mt-[17.07px] mx-[2.22vw] md:mx-[17.07px] min-w-[1.11vw] md:min-w-[4px] h-[1.11vw] md:h-[4px] rounded-full bg-stone-800"></span>
       {desc}
      </li>
     );
    })}
   </ul>
   <span className="bg-yellow-500" />
  </CellWrap>
 );
};
