import dayjs from "dayjs";
import "dayjs/locale/ko";
import { DateLike } from "../data/type";
import type timezone from "dayjs/plugin/timezone";

//@ts-ignore
dayjs.tz = dayjs;

const englandTime = "Africa/Abidjan";
const korea = "Asia/Seoul";

export const TZ = {
 englandTime,
 korea,
};

export const splitYYYYMM = (value: number) => {
 const year = Math.floor(value * 0.01);
 const month = Math.floor(value % 100);

 return { year, month };
};

export const isSameDate = (a: DateLike, b: DateLike) =>
 dayjs.tz(a).isSame(b, "day");

export const isPast = (date: DateLike) => dayjs.tz(date).isBefore(new Date());
export const isPastDay = (date: DateLike) =>
 dayjs.tz(date).isBefore(new Date(), "day");

export const yyyymmToText = (value: number) => {
 const { month, year } = splitYYYYMM(value);
 return year + "년 " + month + "월";
};

export const mmddLabel = (date?: Date | null | number) =>
 dayjs.tz(date || new Date()).format("MM월DD일");
export const yyyymmddLabel = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YYYY년MM월DD일") : "";

export const yyyymmddLabelRange = (start?: Date | null, end?: Date | null) => {
 if (
  dayjs.tz(start || new Date()).isSame(dayjs.tz(end || new Date()), "year")
 ) {
  return mmddLabel(start) + "~" + mmddLabel(end);
 }
 return yyyymmddLabel(start) + "~" + yyyymmddLabel(end);
};

export const diffDay = (start: DateLike, to: DateLike) => {
 return dayjs.tz(to).diff(dayjs.tz(start), "day");
};
export const Dday = (at: DateLike) => {
 const diff = diffDay(new Date(), at) + 1;
 return diff > 0 ? diff : 0;
};
export const yyyymmddRange = (
 start?: DateLike,
 end?: DateLike,
 noStart: string = "",
 noEnd: string = ""
) => {
 return (yyyymmdd(start) || noStart) + "~" + (yyyymmdd(end) || noEnd);
};

export const yyyymmddLabelRangeOrSameDate = (
 start?: Date | null,
 end?: Date | null
) => {
 if (dayjs.tz(start || new Date()).isSame(end || new Date(), "date")) {
  return yyyymmdd(dayjs.tz(start)) || "";
 } else {
  return yyyymmddLabelRange(start, end) || "";
 }
};

export const toDateOrNull = (date?: DateLike) =>
 date ? dayjs.tz(date).toDate() : null;
export const yyyymmddPure = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YYYYMMDD") : "";
export const yyyymmdd = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YYYY.MM.DD") : "";
export const yyyymmddPureStrDivide = (pureYYYYMMDD: string | number) => {
 const dateArr = [
  ...((typeof pureYYYYMMDD === "string"
   ? pureYYYYMMDD
   : pureYYYYMMDD.toString()) as any),
 ];
 const year = dateArr.slice(0, 4).join("");
 const month = dateArr.slice(4, 6).join("");
 const day = dateArr.slice(6, 8).join("");
 const arr = [year, month, day];
 const dateStr = arr.filter((v) => v).join("-");
 const date = new Date(dateStr);
 return { arr, year, month, day, date, dateStr };
};
export const yyyymmddToDate = (pureYYYYMMDD: string | number) => {
 const dateArr = [
  ...((typeof pureYYYYMMDD === "string"
   ? pureYYYYMMDD
   : pureYYYYMMDD.toString()) as any),
 ];
 const year = dateArr.slice(0, 4).join("");
 const month = dateArr.slice(4, 6).join("");
 const day = dateArr.slice(6, 8).join("");
 const dateStr = [year, month, day].filter((v) => v).join("-");
 const date = new Date(dateStr);
 return date;
};
export const dateToYYYYMMDDnumber = (date: DateLike) => {
 return parseInt(dayjs.tz(date).format("YYYYMMDD"));
};

export const generateDateArray = (
 from = new Date(),
 cnt = 30,
 diff: dayjs.ManipulateType = "day"
) => {
 return new Array(cnt)
  .fill(null)
  .map((_, i) => dayjs.tz(from).add(i, diff).toDate());
};

export const generateDateArrayBetween = (from: DateLike, to: DateLike) => {
 const diff = dayjs.tz(to).diff(from, "day");
 if (diff < 0) return [];
 return new Array(diff || 0)
  .fill(null)
  .map((_, i) => dayjs.tz(from).add(i, "day").toDate());
};

export const getMonthDateArray = (date?: DateLike) => {
 const startDate = dayjs.tz(date).startOf("month").toDate();
 const endDate = dayjs.tz(date).endOf("month").toDate();
 return generateDateArrayBetween(startDate, endDate);
};
export const addOneDay = (date?: DateLike) => {
 return dayjs.tz(date).add(1, "day").toDate();
};
export const yyyymmdd요일 = (date?: DateLike) =>
 date ? dayjs.tz(date).locale("ko").format("YYYY.MM.DD ddd") : "";
export const yyyymmddNum = (date?: DateLike) =>
 date ? parseInt(dayjs.tz(date).format("YYYYMMDD")) : 0;
export const yyyymmddW = (date?: DateLike) =>
 date ? dayjs.tz(date).locale("ko").format("YYYY.MM.DD ddd") : "";
export const mmddW = (date?: DateLike) =>
 date ? dayjs.tz(date).locale("ko").format("MM.DD(ddd)") : "";
export const yymmddHHmm = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YY.MM.DD HH:mm") : "";
export const yyyymmddHHmm = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YYYY.MM.DD HH:mm") : "";
export const yyyymmddHHmmA = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YYYY.MM.DD HH:mm A") : "";
export const yyyymmddHHmmLabel = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YY년MM월DD일 HH시mm분") : "";
export const checkInOutDate = (from?: DateLike, to?: DateLike, split = "~") =>
 dayjs.tz(from).format("입실 YYYY.MM.DD") +
 split +
 dayjs.tz(to).format("퇴실 YYYY.MM.DD");
export const checkInOutDateDoubleLine = (from?: DateLike, to?: DateLike) =>
 dayjs.tz(from).format("입실 YYYY.MM.DD") +
 "\n" +
 dayjs.tz(to).format("퇴실 YYYY.MM.DD");
export const yyyymmddHHmmLabel2 = (date?: DateLike) =>
 date ? dayjs.tz(date).format("YYYY년 MM월 DD일 HH시 mm분") : "";
export const yyyymmddHHmmRange = (from?: DateLike, to?: DateLike) =>
 from
  ? dayjs.tz(from).format("YYYY.MM.DD HH:mm")
  : "" + "~" + (to ? dayjs.tz(to).format("YYYY.MM.DD HH:mm") : "");
export const yyyymmddHHmmRangeLabel = (from?: DateLike, to?: DateLike) => {
 if (!from) return dayjs.tz(to).format("YYYY.MM.DD HH:mm") + " 까지";
 if (!to) return dayjs.tz(from).format("YYYY.MM.DD HH:mm") + " 부터";
 return (
  dayjs.tz(from).format("YYYY.MM월DD일 HH:mm") +
  "~" +
  dayjs.tz(to).format("YYYY.MM월DD일 HH:mm")
 );
};
export const hhmm = (from?: DateLike) =>
 dayjs.tz(from || new Date()).format("HH:mm");
export const hhmmA = (from?: DateLike) =>
 dayjs
  .tz(from || new Date())
  .locale("ko")
  .format("A HH:mm");
export const hhmmAss = (from?: DateLike) =>
 dayjs
  .tz(from || new Date())
  .locale("ko")
  .format("A HH:mm:ss");
export const hhmmRange = (from?: DateLike, to?: DateLike) =>
 dayjs.tz(from || new Date()).format("HH:mm") +
 "~" +
 dayjs.tz(to || new Date()).format("HH:mm");
export const MMDDhhmm = (date?: Date | null) =>
 dayjs.tz(date || new Date()).format("MM.DD HH:mm");
export const MMDDhhmmRange = (from?: any, to?: Date | null): string => {
 if (!from) return "";
 // @ts-ignore
 if (from.to) {
  const _from = from as any;
  return MMDDhhmmRange(_from.from, _from.to);
 } else
  return (
   dayjs.tz((from as Date) || new Date()).format("MM.DD HH:mm") +
   "~" +
   dayjs.tz(to || new Date()).format("MM.DD HH:mm")
  );
};

export const minsTohhmm24 = (mins: number | null | undefined) => {
 if (!mins) return 0;
 const hour = Math.floor(mins / 60);
 const min = mins % 60;
 return hour * 100 + min;
};

export const minsTohhmmLabel = (mins: number | null | undefined) => {
 if (!mins) return "";
 const hour = Math.floor(mins / 60);
 const min = mins % 60;
 return hour + "시" + min + "분";
};

export const minsDesc = (mins: number) => {
 const hour = Math.floor(mins / 60);
 const min = mins % 60;
 return { hour, min };
};

export const hhmm24ToHHmm = (hhmm24: number) => {
 let hour: number | string = Math.floor(hhmm24 / 100);
 let min: number | string = hhmm24 % 100;
 if (hour < 10) hour = "0" + hour.toString();
 if (min < 10) min = "0" + min.toString();
 return hour + ":" + min.toString(2);
};
export const endOfDate = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .endOf("day")
  .valueOf();
export const endOfDATE = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .endOf("day")
  .toDate();
export const startOfDATE = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .startOf("day")
  .toDate();
export const startOfDate = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .startOf("day")
  .valueOf();
export const startOfMonthNumber = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .startOf("month")
  .valueOf();
export const endOfMonthNumber = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .endOf("month")
  .valueOf();
export const nextendOfMonthNumber = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .add(1, "month")
  .endOf("month")
  .valueOf();
export const nextendOfMontDate = (date: DateLike) =>
 dayjs
  .tz(date || new Date())
  .add(1, "month")
  .endOf("month")
  .toDate();

export const numToWeekDay = (day: number) => {
 if (day === 0) return "sun";
 if (day === 1) return "mon";
 if (day === 2) return "tue";
 if (day === 3) return "wed";
 if (day === 4) return "thu";
 if (day === 5) return "fri";
 if (day === 6) return "sat";
 throw Error("");
};

export const to4YMMDD = (date: any) => {
 if (!date) {
  return "날짜없음";
 }
 return dayjs.tz(date).toISOString().split("T")[0];
};

export const hypenFy = (
 str: string,
 hypenPlaces: number[] = [4, 9, 14, 19, 24, 29]
) => {
 let HypenPlaces = [...hypenPlaces];
 const codeArray = Array.from(str);
 HypenPlaces = HypenPlaces.filter((h) => h <= codeArray.length);
 HypenPlaces.forEach((place) => {
  codeArray.splice(place, 0, "-");
 });

 return codeArray.join("");
};

export const alphabetOrNumberOnly = (str: string) =>
 str.replace(/[^a-zA-Z0-9]+/g, "");
export const alphabetOnly = (str: string) => str.replace(/[^a-zA-Z]+/g, "");
export const isAlphabetOnly = (str: string) => str.match(/[^a-zA-Z]+/g);

export const dateToTime = (date: Date): { hour: number; min: number } => {
 return {
  hour: date.getHours(),
  min: date.getMinutes(),
 };
};

export interface IVscodeLinkPath {
 relativePath: string;
 line?: number | string;
 column?: number | string;
}

const Base = process.env.NEXT_PUBLIC_MY_COMPUTER_URI || "";

export const getVsCodeLink = ({
 relativePath,
 column,
 line,
}: IVscodeLinkPath) => {
 const lineStr = line ? `:${line}` : "";
 const colStr = column ? `:${column}` : "";
 return `vscode://file${Base}${relativePath}${lineStr}${colStr}`;
};

export const isDateInside = (
 target: DateLike,
 from: DateLike,
 to: DateLike
) => {
 return dayjs.tz(target).isAfter(from) && dayjs.tz(target).isBefore(to);
};

export const isDateInsideSameInclude = (
 target: DateLike,
 from: DateLike,
 to: DateLike
) => {
 return (
  (dayjs.tz(target).isSame(from) || dayjs.tz(target).isAfter(from)) &&
  (dayjs.tz(target).isSame(to) || dayjs.tz(target).isBefore(to))
 );
};

//....
////......
