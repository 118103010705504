import { useContext } from "react";
import { FcellFragment } from "../../app/apollo/type/graphql";
import { Photo } from "../Photo";
import { AppContext } from "../../hook/useApp.context";
import { useNavigate } from "react-router-dom";
import { CellWrap } from "./CellWrap";

interface IProp {
 cell: FcellFragment;
}
export const Square: React.FC<IProp> = ({ cell }) => {
 const { lang } = useContext(AppContext);
 const navigate = useNavigate();
 return (
  <CellWrap
   cell={cell}
   data-aos="fade-up"
   className="cursor-pointer aspect-[5:6] pb-0 p-[1.11vw] md:p-[8.53px] bg-white overflow-hidden rounded-[4.44vw] md:rounded-[34.13px] shadow border-[0.28vw] md:border-[2.13px] border-neutral-200"
  >
   <div className="aspect-square relative overflow-hidden rounded-[4.44vw] ">
    <Photo
     width={144}
     height={140}
     size={500}
     defaultSrc={"/144x140.png"}
     file={cell.image}
     className="w-full h-full object-cover border-none outline-none"
    ></Photo>
   </div>
   <h6 className="text-center pb-[5.56vw] md:pb-[42.67px] text-gray-800 text-[3.33vw] md:text-[25.6px] font-bold pt-[2.78vw] md:pt-[21.33px] !leading-none">
    {lang(cell.name)}
   </h6>
  </CellWrap>
 );
};
