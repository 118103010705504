import ReactDOM from "react-dom/client";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { Router } from "./page/router";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo";
import { App } from "./App";
import "react-toastify/dist/ReactToastify.css";
import { LangSet } from "./data/lang.static";
import { LocalStorage } from "./utils/localstorage/StorageAddition";

LangSet(LocalStorage.getItem("lang") || navigator.language);

const root = ReactDOM.createRoot(
 document.getElementById("root") as HTMLElement
);
root.render(
 <ApolloProvider client={apolloClient}>
  <App />
 </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
