import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type Variant = "flat" | "shallow" | "border" | "text";
type Color = "primary-dark" | "primary" | "red" | "grey" | "neutral";

export interface IProp extends React.HTMLAttributes<HTMLButtonElement> {
 variant?: Variant;
 selectedVarient?: Variant;
 color?: Color;
 size?: "S" | "L";
 disabled?: boolean;
 selected?: boolean;
}

export const BasicButton: React.FC<IProp> = ({
 selected,
 selectedVarient = "border",
 color = selected ? "primary" : "grey",
 variant = "flat",
 size,
 disabled,
 className,
 ...props
}) => {
 variant = selected ? selectedVarient : variant;
 const buttonClasses = twMerge(
  classNames(
   "cursor-pointer w-full rounded text-neutral-600 text-[2.22vw] md:text-[17.07px] flex items-center justify-center h-[11.11vw] md:h-[85.33px] focus:outline-none transition-colors duration-200 ease-in-out",
   {
    "px-[0.83vw] md:px-[6.4px] h-[6.67vw] md:h-[51.2px] text-[1.67vw] md:text-[13.63px]":
     size === "S",
    "px-[1.11vw] md:px-[8.53px] h-[14.44vw] md:h-[110.93px] font-semibold text-[3.33vw] md:text-[26.67px]":
     size === "L",
   },
   {
    "bg-primary-500 hover:bg-primary-600 text-white":
     color === "primary" && variant === "flat",
    "bg-blue-500 hover:bg-blue-800 text-white":
     color === "primary-dark" && variant === "flat",
    "bg-red-500 hover:bg-red-600 text-white":
     color === "red" && variant === "flat",
    "bg-neutral-800 hover:bg-neutral-500 text-white":
     color === "neutral" && variant === "flat",
    "text-neutral-400 !bg-neutral-200 cursor-auto":
     disabled && variant === "flat",
    "text-neutral-600 bg-neutral-100 hover:bg-neutral-200":
     color === "grey" && variant === "flat",
   },
   {
    "bg-white hover:bg-neutral-50 text-neutral-800 border border-neutral-300":
     color === "neutral" && variant === "border",
    "bg-white hover:bg-primary-50 font-semibold text-primary-500 border-[0.56vw] md:border-[4.27px] border-primary-500":
     color === "primary" && variant === "border",
    "bg-white bg-blue-50 hover:bg-blue-100 text-primary-500 border border-blue-500":
     color === "primary-dark" && variant === "border",
    "bg-white hover:bg-red-50 text-red-500 border border-red-500":
     color === "red" && variant === "border",
    "!bg-white text-neutral-400 border border-neutral-400 cursor-auto":
     disabled && variant === "border",
   },
   {
    "bg-neutral-50 text-neutral-800 hover:bg-neutral-100":
     color === "neutral" && variant === "shallow",
    "bg-blue-50 text-blue-500 hover:bg-blue-100":
     color === "primary" && variant === "shallow",
    "bg-blue-100 text-blue-500 hover:bg-blue-50":
     color === "primary-dark" && variant === "shallow",
    "bg-red-50 text-red-500 hover:bg-red-100":
     color === "red" && variant === "shallow",
    "text-neutral-400 !bg-neutral-200 cursor-auto":
     disabled && variant === "shallow",
   },
   {
    "text-neutral-400 hover:text-neutral-600":
     color === "grey" && variant === "text",
    "text-neutral-800 hover:text-neutral-600":
     color === "neutral" && variant === "text",
    "text-blue-500 hover:text-blue-600":
     color === "primary" && variant === "text",
    "text-blue-600 hover:text-blue-500":
     color === "primary-dark" && variant === "text",
    "text-red-500 hover:text-red-600": color === "red" && variant === "text",
    "!bg-white !text-neutral-400 cursor-auto": disabled && variant === "text",
   },
   className
  )
 );
 return (
  <button
   type="button"
   disabled={disabled}
   className={buttonClasses}
   {...props}
  />
 );
};
