import { ReactNode } from "react";
import {
 ItemFindByIdQuery,
 useItemFindByIdQuery,
} from "../app/apollo/type/graphql";
import { TUseModal } from "../hook/useModal";
import Modal from "./Modal";
import { Button } from "./button";

interface IProp {
 item: ItemFindByIdQuery["ItemFindById"];
 modalHook: TUseModal<IDetailModalInfo>;
}

const ItemDetailModal: React.FC<IProp> = ({ item, modalHook }) => {
 const { itemId } = modalHook.info || {};

 return (
  <Modal modalHook={modalHook}>
   <div className="max-h-[80vh] overflow-auto">
    <h6 className="mb-6 text-neutral-800 text-2xl font-semibold">
     {item?.name?.ko}
    </h6>

    <div className=" flex flex-col gap-4 mb-8">
     {Object.entries({ ...item?.name }).map(([langKey, value]) => {
      return (
       <div className="flex items-center">
        <h6 className="flex font-bold justify-between mr-4">{langKey}</h6>
        {value as string}
       </div>
      );
     })}
    </div>

    <div className="flex flex-col gap-6 ">
     {Object.entries({ ...item?.information }).map(([langKey, value]) => {
      return (
       <div>
        <h6 className="flex font-bold justify-between">{langKey}</h6>
        {value as string}
        <audio
         className="mt-2 scale-75 "
         controls
         src={`https://d2v5p8jgjm0j1e.cloudfront.net/${item?._id}/${langKey}.mp3`}
        ></audio>
       </div>
      );
     })}
    </div>

    <div className="mt-6">
     <Button
      size="L"
      className="w-full"
      onClick={modalHook.close}
      children="확인"
     />
    </div>
   </div>
  </Modal>
 );
};

export interface IDetailModalInfo {
 itemId: string;
}

export interface IItemDetailModal {
 modalHook: TUseModal<IDetailModalInfo>;
}

export const AlertModalDetail: React.FC<IItemDetailModal> = ({ modalHook }) => {
 const itemId = modalHook?.info?.itemId!;
 const { data: item } = useItemFindByIdQuery({
  variables: {
   itemId,
   selectAll: true,
  },
 });
 return <ItemDetailModal item={item?.ItemFindById} modalHook={modalHook} />;
};

export default ItemDetailModal;
