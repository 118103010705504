import { MapPinIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { FiconPairFragment, LineType } from "../../app/apollo/type/graphql";
import { DoorIcon } from "../icon/DoorIcon";
import { Icons } from "../../data/icon.const";
import { useContext } from "react";
import { AppContext } from "../../hook/useApp.context";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

interface IInfoCellProp {
 infocell: FiconPairFragment;
 size?: "L";
}

export const InfoCell: React.FC<IInfoCellProp> = ({ infocell, size }) => {
 const { lang } = useContext(AppContext);
 const sizeL = size === "L";
 let Icon: React.FC<React.ComponentProps<typeof MapPinIcon>> | undefined =
  undefined;
 if (infocell.type === LineType.Address) Icon = MapPinIcon;
 if (infocell.type === LineType.CheckInOut) Icon = DoorIcon as any;
 if (infocell.type === LineType.PhoneNumber) Icon = PhoneIcon;
 if (infocell.type === LineType.Custom) Icon = (Icons as any)[infocell.icon!];

 return (
  <li
   className={twMerge(
    classNames(
     "flex items-center py-[1vw] md:py-[8.53px] gap-x-[2.78vw] md:gap-x-[21.33px]",
     {
      "gap-[3.34vw] md:gap-[25.6px] py-[2.78vw] md:py-[21.33px]": sizeL,
     }
    )
   )}
  >
   {Icon && (
    <Icon
     className={twMerge(
      classNames(" text-primary-500 w-[3.89vw] md:w-[29.87px] h-auto", {
       "w-[5.56vw] md:w-[42.67px] !h-auto ": sizeL,
      })
     )}
    />
   )}
   <span
    className={twMerge(
     classNames("text-[3.33vw] md:text-[25.6px]", {
      "text-[3.89vw] md:text-[29.87px]": sizeL,
     })
    )}
   >
    {lang(infocell.title)}
   </span>
  </li>
 );
};
