import React, { useEffect, useState } from "react";
import {
 ToastContainer,
 cssTransition,
 ToastContainerProps,
} from "react-toastify";
import ReactDOM from "react-dom";

export interface IProps extends ToastContainerProps {}

export const Tostify: React.FC<IProps> = ({ ...props }) => {
 const [up, setUp] = useState(0);
 const container = document.getElementById("App");
 useEffect(() => {
  setUp(1);
 }, []);
 if (!container) return null;

 return ReactDOM.createPortal(
  <ToastContainer
   position={"bottom-center"}
   {...props}
   closeOnClick
   autoClose={3000}
  />,
  container!
 );
};

export default Tostify;
