interface IProp extends React.SVGProps<SVGSVGElement> {
 lat: number;
 lng: number;
 color: "teal" | "red" | "amber" | "darkblue";
 onClick?: () => void;
}

export const MapPin: React.FC<IProp> = ({
 lat,
 lng,
 color,
 onClick,
 ...props
}) => {
 const colorMap = {
  teal: "#2dd4bf",
  red: "#f87171",
  amber: "#fbbf24",
  darkblue: "#264186",
 };

 return (
  <svg
   {...props}
   onClick={onClick}
   //@ts-ignore
   lat={lat}
   lng={lng}
   width="31"
   height="31"
   viewBox="0 0 31 31"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <mask
    id="mask0_440_424"
    style={{
     maskType: "luminance",
    }}
    maskUnits="userSpaceOnUse"
    x="5"
    y="2"
    width="21"
    height="27"
   >
    <path
     d="M15.5 28C15.5 28 24.875 20.5 24.875 12.375C24.875 7.1975 20.6775 3 15.5 3C10.3225 3 6.125 7.1975 6.125 12.375C6.125 20.5 15.5 28 15.5 28Z"
     fill="white"
     stroke="white"
     stroke-width="2"
     stroke-linejoin="round"
    />
    <path
     d="M15.5 16.125C15.9925 16.125 16.4801 16.028 16.9351 15.8395C17.39 15.6511 17.8034 15.3749 18.1517 15.0267C18.4999 14.6784 18.7761 14.265 18.9645 13.8101C19.153 13.3551 19.25 12.8675 19.25 12.375C19.25 11.8825 19.153 11.3949 18.9645 10.9399C18.7761 10.485 18.4999 10.0716 18.1517 9.72335C17.8034 9.37513 17.39 9.09891 16.9351 8.91045C16.4801 8.722 15.9925 8.625 15.5 8.625C14.5054 8.625 13.5516 9.02009 12.8483 9.72335C12.1451 10.4266 11.75 11.3804 11.75 12.375C11.75 13.3696 12.1451 14.3234 12.8483 15.0267C13.5516 15.7299 14.5054 16.125 15.5 16.125Z"
     fill="black"
     stroke="black"
     stroke-width="2"
     stroke-linejoin="round"
    />
   </mask>
   <g mask="url(#mask0_440_424)">
    <path d="M0.5 0.5H30.5V30.5H0.5V0.5Z" fill={colorMap[color]} />
   </g>
  </svg>
 );
};
