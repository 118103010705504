import { Fragment, ReactElement, ReactNode, useContext } from "react";
import { AppContext, useAppContext } from "../../hook/useApp.context";
import { Navigate } from "react-router-dom";
import {
 AdminContext,
 useAdminContextProvider,
} from "../../hook/admin.context";

interface IProp {
 children: ReactElement;
}

export const ProtectedRoute: React.FC<IProp> = ({ children }) => {
 const { redirectToAuth, authLoading, store } = useAppContext(); // Use context
 const adminCtx = useAdminContextProvider({});

 if (authLoading) return null;

 if (redirectToAuth) {
  // Redirect to login page if not authenticated
  return <Navigate to="/auth" replace />;
 }

 return (
  <AdminContext.Provider value={adminCtx}>
   <Fragment key={store?._id + "EditRoute"}>{children}</Fragment>{" "}
  </AdminContext.Provider>
 );
};
