import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { FcellFragment } from "../../app/apollo/type/graphql";

interface IProp
 extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
 > {
 cell: FcellFragment;
}

export const CellWrap: React.FC<IProp> = ({ cell, className, ...props }) => {
 const navigate = useNavigate();

 return (
  <div
   onClick={() => {
    if (cell.link) navigate(cell.link);
   }}
   className={classNames("fade-up", className)}
   {...props}
  />
 );
};
