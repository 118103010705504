import color from "color";

export const cssVar = (keyVal: [string, string | number][]) => {
 const keyValFormat = keyVal.map(([key, val]) => {
  if (key.includes("--")) return [key, val];
  return ["--" + key, val];
 });
 const style = Object.fromEntries(keyValFormat) as React.CSSProperties;
 return style;
};

export const colorInlineObjectGenerater = (
 mainColor?: string | null
): React.CSSProperties => {
 if (!mainColor) return {};
 const isDark = color(mainColor).isDark();
 const darkColor = color(mainColor).darken(0.15).hex();
 const lightColor = color(mainColor).lighten(0.15).hex();
 const style = cssVar([
  ["text-primary-over", color(mainColor).lightness() < 70 ? "#fff" : "#262626"],
  ["primary-color", mainColor],
  ["primary-color-light", lightColor],
  ["primary-color-dark", darkColor],
  ["primary-color-on-text", isDark ? "#fff" : "#333"],
 ]) as React.CSSProperties;

 return { ...style, ...getLineaer(mainColor) };
};

export const getLineaer = (mainColor: string) => {
 return cssVar([
  ["primary-color-50", color(mainColor).lighten(0.7).desaturate(0.1).hex()],
  ["primary-color-100", color(mainColor).lighten(0.6).desaturate(0.05).hex()],
  ["primary-color-200", color(mainColor).lighten(0.45).hex()],
  ["primary-color-300", color(mainColor).lighten(0.3).hex()],
  ["primary-color-400", color(mainColor).lighten(0.15).hex()],
  ["primary-color-500", color(mainColor).hex()],
  ["primary-color-600", color(mainColor).darken(0.15).saturate(0.05).hex()],
  ["primary-color-700", color(mainColor).darken(0.3).saturate(0.1).hex()],
  ["primary-color-800", color(mainColor).darken(0.45).saturate(0.15).hex()],
  ["primary-color-900", color(mainColor).darken(0.6).saturate(0.2).hex()],
 ]);
};
