import { UserRole, useSignInMutation } from "../app/apollo/type/graphql";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { useSignin } from "../hook/useSignin";

export const Login = () => {
 const { handleSignIn, nameHook, passwordHook } = useSignin(UserRole.Provider);
 return (
  <section className="bg-gray-50 dark:bg-gray-900 flex items-center justify-center ">
   <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <a
     href="#"
     className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
    >
     <img className="w-8 h-8 mr-2" src="/logo.svg" alt="logo" />
     [Logo Place Holder]
    </a>
    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
     <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
       관리자 인증
      </h1>
      <form
       onSubmit={(e) => {
        e.preventDefault();
        handleSignIn();
       }}
       className="space-y-4 md:space-y-6"
       action="#"
      >
       <div>
        <Input placeholder="관리자명" {...nameHook} />
       </div>
       <div>
        <Input placeholder="패스워드" {...passwordHook} type="password" />
       </div>

       <Button
        className="w-full text-white bg-blue-800 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-800 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
        type="submit"
       >
        로그인
       </Button>
      </form>
     </div>
    </div>
   </div>
  </section>
 );
};
