export default function Page404() {
 return (
  <>
   {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
   <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div className="text-center">
     <p className="text-base font-semibold text-blue-800">404</p>
     <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
      페이지를 찾을수 없습니다.
     </h1>
     <p className="mt-6 text-base leading-7 text-gray-600">
      조회하신 페이지는 존재하지 않는 페이지 입니다.
     </p>
     <div className="mt-10 flex items-center justify-center gap-x-6">
      <a
       href="#"
       className="rounded-md bg-blue-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-800"
      >
       홈으로 돌아가기
      </a>
      <a href="#" className="text-sm font-semibold text-gray-900">
       문의하기 <span aria-hidden="true">&rarr;</span>
      </a>
     </div>
    </div>
   </main>
  </>
 );
}
