export class MemoryStorage implements Storage {
 clear(): void {
  this.map.clear();
 }
 key(index: number): string | null {
  return [...this.map.entries()]?.[index]?.[1];
 }
 get length() {
  return this.map.size;
 }

 private static instance: MemoryStorage;
 private constructor() {}

 private map = new Map();

 public removeItem(key: string) {
  this.map.delete(key);
 }

 public setItem(key: string, value: string) {
  this.map.set(key, value);
 }

 public getItem(key: string) {
  return this.map.get(key);
 }

 public static get Instance() {
  if (this.instance != null) return this.instance;
  return new MemoryStorage();
 }
}
