import { useLocation } from "react-router-dom";
import { useModal } from "./useModal";
import React, { useContext, useEffect, useState } from "react";
import {
 FstoreFragment,
 useAdminQuery,
 useStoreFindByIdQuery,
} from "../app/apollo/type/graphql";
import { IPromptModalInfo } from "../components/systemModal/PromptModal";
import { IConfirmModalInfo } from "../components/systemModal/ConfirmModal";
import { IAlertModalInfo } from "../components/systemModal/AlertModal";
import { IDetailModalInfo } from "../components/ItemDetailModal";
import { getFromUrl } from "../utils/getFromUrl";
import { CACHE, LangSet, getLangCode } from "../data/lang.static";
import { LocalStorage } from "../utils/localstorage/StorageAddition";
import { IRoomNumberPopModalInfo } from "../components/RoomNumberPop.modal";
import { ICellEditInfo } from "../components/CellEditModal.model";
import { useUpdateComponent } from "./useUpdateComponent";
import { useIframCommunicate } from "./useIframCommunication";
import { useMemorialState } from "./useMemorialState";

export type TUseAdminContext = ReturnType<typeof useAdminContextProvider>;

export const useAdminContextProvider = ({ ...props }) => {
 const { val: roomId, setVal: setRoomId } = useMemorialState(
  "",
  "lastOpenAdminChatRoomId"
 );
 return {
  roomId,
  setRoomId,
 };
};

export const AdminContext = React.createContext<TUseAdminContext>({} as any);
export const useAdminContext = () => {
 return useContext(AdminContext);
};
