export const enumToArray = <T extends Object>(enums: T): string[] =>
 Object.values(enums);

export const statusDestructor = <
 T extends string | number,
 E extends { [key: string]: string | number }
>(
 value: T | null | undefined,
 ENUM: E
): Record<E[keyof E], boolean> => {
 const result: any = {};
 enumToArray(ENUM).forEach((enumStatus) => {
  result[enumStatus] = value === enumStatus;
 });
 return result;
};

export const enumCheck = statusDestructor;
