const isAvailWindow = "Notification" in window;

type CallBack = (permission: NotificationPermission) => void;

function askPersmisson(cb?: CallBack) {
 if (!("Notification" in window)) {
  alert("알림을 지원하지 않는 브라우저입니다.");
  return false;
 }

 Notification.requestPermission().then(function (permission) {
  console.log("@@@permission", permission);
  cb?.(permission);
  if (permission === "granted") {
   new Notification("알림이 정상적으로 설정되었습니다.", {
    icon: window.location.origin + "/public/img/logo.svg",
    badge: "뱃지",
    tag: "태그",
    body: "바디",
    silent: false,
   });
  }
 });
}

function isGranted() {
 return isAvailWindow && Notification.permission === "granted";
}

export const WindowNotification = {
 askPersmisson,
};

export const checkNotificationSetted = () => {
 if (!("Notification" in window)) {
  return false;
 } else if (Notification.permission === "granted") {
  return true;
 }
 return false;
};
