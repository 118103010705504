import { ReactNode } from "react";
import { TUseModal } from "../../hook/useModal";
import Modal from "../Modal";
import { Button } from "../button";

export interface IAlertModalInfo {
 Title?: string;
 children?: any;
}

export interface IPomptModalProps {
 modalHook: TUseModal<IAlertModalInfo>;
 children?: ReactNode;
}
export const AlertModal: React.FC<IPomptModalProps> = ({ modalHook }) => {
 const { Title, children: infoChildren } = modalHook.info || {};

 return (
  <Modal modalHook={modalHook}>
   <div>
    <h6 className="mb-6 text-neutral-800 text-2xl font-semibold">{Title}</h6>
    {infoChildren}
    <div className="mt-6">
     <Button
      size="L"
      className="w-full"
      onClick={modalHook.close}
      children="확인"
     />
    </div>
   </div>
  </Modal>
 );
};

export default AlertModal;
