import { useLocation } from "react-router-dom";
import { useModal } from "./useModal";
import React, { useContext, useEffect, useState } from "react";
import {
 FstoreFragment,
 useAdminQuery,
 useChatRoomUpdateMutation,
 useStoreFindByIdQuery,
 useStoreUpdateMutation,
} from "../app/apollo/type/graphql";
import { IPromptModalInfo } from "../components/systemModal/PromptModal";
import { IConfirmModalInfo } from "../components/systemModal/ConfirmModal";
import { IAlertModalInfo } from "../components/systemModal/AlertModal";
import { IDetailModalInfo } from "../components/ItemDetailModal";
import { getFromUrl } from "../utils/getFromUrl";
import { CACHE, LangSet, getLangCode } from "../data/lang.static";
import {
 LocalStorage,
 iamTopWindow,
} from "../utils/localstorage/StorageAddition";
import { IRoomNumberPopModalInfo } from "../components/RoomNumberPop.modal";
import { ICellEditInfo } from "../components/CellEditModal.model";
import { useUpdateComponent } from "./useUpdateComponent";
import { useIframCommunicate } from "./useIframCommunication";

export type TUseAppContext = ReturnType<typeof useAppContextProvider>;

export const useAppContextProvider = ({ ...props }) => {
 const { upKey, updateComponent } = useUpdateComponent();
 const [previewStore, setPreviewStore] = useState<FstoreFragment>();
 const [updateMu] = useChatRoomUpdateMutation();

 const resetLang = (langcode: string) => {
  if (!iamTopWindow) {
   alert("미리보기에서는 언어변경을 하실 수 없습니다.");
   return;
  }
  LocalStorage.set("lang", langcode);
  CACHE.langCode = langcode;
  const roomNum = LocalStorage.getItem("room") || "";
  const mid = LocalStorage.getItem("mid") || "";
  const key = roomNum + mid;
  if (roomNum && mid) {
   updateMu({
    variables: {
     roomkey: key,
     input: {
      customerLang: langcode,
     },
    },
   });
  }
  LangSet(langcode);
  updateComponent();
 };

 useIframCommunicate({
  setStore: setPreviewStore as any,
 });

 const storeId = "665ee035d56d4aa639a54e82";
 const {
  loading: authLoading,
  data: adminResult,
  refetch: refetchAdmin,
 } = useAdminQuery({
  fetchPolicy: "no-cache",
  //   skip: !hasAdminCookie,
 });

 const { data, loading } = useStoreFindByIdQuery({
  fetchPolicy: "cache-first",
  variables: {
   storeId: storeId!,
  },
  skip: !storeId,
 });

 useEffect(() => {
  if (data?.StoreFindById?._id)
   LocalStorage.setItem("sid", data?.StoreFindById?._id);
 }, [data?.StoreFindById?._id]);

 const store = previewStore || data?.StoreFindById;

 console.log("@@@store", store);

 const [search, setSearch] = useState("");

 const roomNumberPOpModal = useModal<IRoomNumberPopModalInfo>();
 const promptModalHook = useModal<IPromptModalInfo>();
 const confirmModalHook = useModal<IConfirmModalInfo>();
 const alertModalHook = useModal<IAlertModalInfo>();
 const itemDetailModalHook = useModal<IDetailModalInfo>();
 const [updateKey, setUpdateKey] = useState(0);

 const admin = adminResult?.Admin;
 const redirectToAuth = !authLoading && !admin;

 useEffect(() => {
  if (!store) return;
  const target = document.getElementById("Splash");
  if (!target) return;
  target?.classList?.add("fade-out");
  target.style.opacity = "1";
  target.addEventListener("animationend", () => {
   // Option 1: Set pointer-events to none after animation
   // element.style.pointerEvents = 'none';

   // Option 2: Set display to none after animation
   target.style.display = "none";
  });
 }, [!!store]);

 const langCode = CACHE.langCode || getLangCode(navigator.language);

 const langSearch = <T>(Obj: T): T => {
  if (Array.isArray(Obj)) {
   return Obj.map((obj) => {
    return langSearch(obj);
   }) as any;
  }
  return Object.fromEntries(
   Object.entries(Obj as any).map(([key, value]) => {
    const newLangKey = key.replace("_ko__", `_${langCode}__`);
    return [newLangKey, value] as any;
   })
  ) as any;
 };

 useEffect(() => {
  LocalStorage.setItem("lang", langCode);
  CACHE.langCode = langCode;
  LangSet(langCode!);
  setUpdateKey(updateKey + 1);
 }, [langCode]);

 //lang for dynamic
 const lang = (obj: any) => {
  console.log("langCode??", langCode);
  if (!obj) return "";
  return obj[langCode];
 };

 return {
  loading,
  langCode,
  lang,
  store,
  admin,
  search,
  setSearch,
  updateComponent,
  refetchAdmin,
  authLoading,
  redirectToAuth,
  promptModalHook,
  confirmModalHook,
  alertModalHook,
  itemDetailModalHook,
  roomNumberPOpModal,
  resetLang,
 };
};

export const AppContext = React.createContext<TUseAppContext>({} as any);
export const useAppContext = () => {
 return useContext(AppContext);
};
