import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { L, LangOps } from "../data/lang.static";
import { useNavigate } from "react-router-dom";
import { NativeSelecter } from "../components/basic/NativeSelecter";
import { Group } from "../components/cell/Group";
import { useLocation } from "react-router-dom";

export const Main = () => {
 const { pathname } = useLocation();
 const navigate = useNavigate();
 const { store, lang, langCode, updateComponent, resetLang } =
  useContext(AppContext);
 const page = store?.pages?.find((p) => p.path === pathname);

 return (
  <div className="w-full px-[5.56vw] md:px-[42.67px] relative py-[22.22vw] md:py-[170.67px] flex flex-col bg-white shadow">
   <div className="flex justify-between">
    <div className="flex gap-[2.5] flex-col">
     <div className="text-[5.56vw] md:text-[42.67px] font-normal !leading-tight">
      {L["WELLCOME"]}
     </div>
     <div className="text-[5.56vw] md:text-[42.67px] font-bold">
      {lang(store?.name)}
     </div>
    </div>
    <div className=" ">
     <NativeSelecter
      onChange={resetLang}
      options={LangOps}
      value={langCode}
      placeholder={L["Language"]}
      selectClasses="w-[66.67vw] w-fit text-center  py-[1.11vw] md:py-[8.53px] px-[2.22vw] md:px-[17.07px]"
      className="border md:border text-[3.33vw] md:text-[25.6px]"
      noCicon
     />
    </div>
   </div>
   <div className="flex flex-col py-[11.11vw] md:py-[85.33px] gap-[11.11vw] md:gap-[85.33px] ">
    {page?.groups?.map((g) => {
     return <Group key={g.key} group={g} />;
    })}
   </div>
  </div>
 );
};

export default Main;
