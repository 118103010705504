// 크로스 도메인 iframe 에서 localStorage를 사용하기 위함.

import { MemoryStorage } from "./memoryStorage";
import { StorageAddition } from "./StorageAddition";

export const onlyTopWindow = (fn: () => void) => {
 if (iamTopWindow) {
  fn();
 }
};
export const iamTopWindow = window.top === window.self;

export const LocalStorage = (() => {
 if (iamTopWindow) {
  return new StorageAddition(localStorage);
 } else {
  return new StorageAddition(MemoryStorage.Instance);
 }
})();
export const LocalStorage2 = new StorageAddition(LocalStorage);
export const SessionStroage = (() => {
 // iamTopWindow
 if (iamTopWindow) {
  return new StorageAddition(sessionStorage);
 } else {
  return new StorageAddition(MemoryStorage.Instance);
 }
})();

export const SessionFisrtLocalStorage = {
 setItem(key: string, value: string) {
  SessionStroage.setItem(key, value);
  LocalStorage.setItem(key, value);
 },
 getItem(key: string) {
  return SessionStroage.getItem(key) || LocalStorage.getItem(key);
 },
 removeItem(key: string, removeBoth?: boolean) {
  SessionStroage.removeItem(key);
  if (removeBoth) LocalStorage.removeItem(key);
 },
};

//local storage key
export const LSK = {
 inf: "inf",
 machineId: "machineId",
 priceListTab: "priceListTab",
 buypageLock: (buypageId: string) => "BuypageLock" + buypageId,
 buyerSession: (storeOwnerId: string) => "BSK" + storeOwnerId,
 buypageBasket: (buypageId: string) => "Basket" + buypageId,
 lastManageStore: (userId: string) => "lastManageStore" + userId,
 buyerStoreId: "storeId",
 serviceProvider: "serviceProvider",
 oauthSkip: "oauthSkip",
 productDetailCalandarView: "productDetailCalandarView",
 productCalandarDefaultView: "productCalandarDefaultView",
 timelineCalandarDefaultView: "timelineCalandarDefaultView",
 testDomainList: "testDomainList",
 approachStoreOwnerId: "approachStoreOwnerId",
 remainPointAlert: "remainPointAlert",
 kioskInputInfo: "kioskInputInfo",
 additionalPayMessageResult: (id: string) => "additionalPayMessageResult" + id,
 aireportCount: (storeId: string) =>
  "aiReportCount" + storeId + new Date().getFullYear() + new Date().getMonth(),
 lastInputProdData: (itemId: string) => "lastInputData" + itemId,
 kioskSavedBundleKey: (storeId: string) => "kioskSavedBundleKey" + "",
 nextOpenPopupTime: (modalId: string) => "nextOpen" + modalId,
 autoOpenPopupLastUpdatedAt: (modalId: string) =>
  "autoOpenPopupLastUpdatedAt" + modalId,
 inLoginNextOpenPasswordOpenModal: (userId: string) =>
  userId + "nextOpenPasswordOpenModal",
};
