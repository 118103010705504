import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { L, LangOps } from "../data/lang.static";
import { Photo } from "../components/Photo";
import { BasicButton } from "../components/basic/Button.ratio";
import { useNavigate } from "react-router-dom";
import { NativeSelecter } from "../components/basic/NativeSelecter";

export const Intro = () => {
 const navigate = useNavigate();
 const { store, lang, langCode, updateComponent, resetLang } =
  useContext(AppContext);

 return (
  <div className="w-full relative h-[100svh] lg:h-[100vh] flex flex-col bg-white shadow">
   <div className="w-full h-[83.33vw] md:h-[640px] relative rounded-bl-[13.89vw] md:rounded-bl-[106.67px] overflow-hidden rounded-br-[13.89vw] md:rounded-br-[106.67px]">
    <Photo
     className="object-cover bg-fixed object-center w-full h-full"
     size={1000}
     file={store?.mainImage}
     defaultSrc={"/360x300.png"}
    />
   </div>
   <div className="flex h-full my-[11.11vw] md:my-[85.33px] gap-[0.69vw] md:gap-[5.33px] flex-col items-center justify-between">
    <div className="text-center text-[5vw] md:text-[38.4px] font-bold">
     <h6 className="font-bold text-[5.56vw] md:text-[42.67px]">
      {L["WELLCOME"]}
     </h6>
     <h1 className="font-semibold">{lang(store?.name)}</h1>
    </div>
    <div className="mx-auto ">
     <NativeSelecter
      onChange={resetLang}
      options={LangOps}
      value={langCode}
      placeholder={L["Language"]}
      className="w-[66.67vw] md:w-[512px]"
     />
     <div className="text-gray-800 text-center mt-[5.56vw] md:mt-[42.67px] !leading-none text-[3.89vw] md:text-[29.87px] font-normal">
      {L["Language_Desc"]}
     </div>
    </div>

    <div>
     <BasicButton
      onClick={() => {
       navigate("/main");
      }}
      color="primary"
      className="font-semibold w-[88.89vw] text-[5vw] md:text-[38.4px] md:w-[682.67px] rounded-full"
     >
      {L["Start Now"]}
     </BasicButton>
    </div>
   </div>
  </div>
 );
};

export default Intro;
