export type TMaybe<T> = T | null | undefined;
export type TboolStr = "true" | "false";
export const boolToString = (boolean: TMaybe<boolean>): TboolStr =>
 boolean ? "true" : "false";
export const strBoolean = (boolean?: TMaybe<TboolStr> | string): boolean => {
 if (boolean === "false") return false;
 else if (boolean === "true") return true;
 return false;
};
export const boolToNum = (bool: boolean) => (bool ? 1 : 0);
export const numToBool = (num: number) => (num ? true : false);
export const strNumToBool = (str: string) => (str === "1" ? true : false);

export const orNull = (conidtion?: any, value?: any) => {
 return conidtion ? value : null;
};

export const orUndefined = (conidtion?: any, value?: any) => {
 return conidtion ? value : undefined;
};
