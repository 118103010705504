import { gql } from "@apollo/client";

export const F_COLLECTION_DATA_INTERFACE = gql`
 fragment FcollectionDataInterface on CollectionDataInterface {
  _id
  createdAt
  updatedAt
 }
`;

export const F_INPUTERROR = gql`
 fragment FinputError on InputError {
  code
  message
  details
 }
`;

export const F_PAGEINFO = gql`
 fragment FoffsetPagingInfo on OffsetPagingInfo {
  pageIndex
  pageItemCount
  currentItemCount
  totalPageCount
  totalDocumentCount
 }
`;
