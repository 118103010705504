import { getRefetch } from "./../utils/getRefetch";
import { toast } from "react-toastify";
import { UserRole, useSignInMutation } from "../app/apollo/type/graphql";
import { Validater } from "../utils/validater";
import { useInput } from "./useInput";
import { useMemorialInput } from "./useMemorialState";
import { useNavigate } from "react-router-dom";
import { ADMIN } from "../apollo/gql/admin";
export const useSignin = (role: UserRole) => {
 const navigate = useNavigate();
 const [signIn] = useSignInMutation({
  ...getRefetch(ADMIN),
  awaitRefetchQueries: true,
  onCompleted(data, clientOptions) {
   if (data.SignIn.ok) {
    navigate("/admin/chats");
   } else {
    toast.error(data.SignIn.error?.message || "문제가 발생했습니다.");
   }
  },
 });
 const nameHook = useMemorialInput("", "signinInputMemory");
 const passwordHook = useInput("");
 const { validate } = new Validater([
  {
   value: nameHook.value,
   failMsg: "이름을 입력해주세요.",
  },
  {
   value: passwordHook.value,
   failMsg: "패스워드를 입력 해주세요.",
  },
 ]);

 const handleSignIn = () => {
  if (validate())
   signIn({
    variables: {
     role,
     name: nameHook.value,
     password: passwordHook.value,
    },
   });
 };

 return {
  handleSignIn,
  nameHook,
  passwordHook,
 };
};
